import Vue from 'vue';
// modules
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import PortalVue from 'portal-vue';
import VueTouch from 'vue-touch';
import { UUI } from '/ubibi_frontend_components_library';
// scripts
import 'virtual:svg-icons-register';
import router from './services/router.js';
import store from './store';
import { initHttpService } from '@/services/http.js';
// components
import App from './App.vue';
import FileLoaderField from '@/components/FileLoaderField.vue';


if (import.meta.env.DEV) {
    console.log(import.meta.env);
}

Vue.prototype.$links = Vue.$links = {
    api: import.meta.env.VITE_API_URL,
    uploads: import.meta.env.VITE_UPLOADS_URL,
    admin: import.meta.env.VITE_ADMIN_URL,
    marketplace: import.meta.env.VITE_MARKETPLACE_URL,
    partner: import.meta.env.VITE_PARTNER_URL,
};

initHttpService();

Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(PortalVue);
Vue.use(VueTouch, { name: 'VueTouch' });
Vue.use(UUI, {
    form: {
        widgets: {
            loader: FileLoaderField,
        },
    },
});

export default new Vue({
    el: '#app',
    store,
    router,
    render(h) {
        return h(App);
    },
});