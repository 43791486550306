import useBaseApi from '@/api/useBaseApi.js';
import { HTTP } from '@/services/http.js';


const { get, add, change, remove, aggregations } = useBaseApi('part_categories');

export default {
    get,
    add,
    change,
    remove,
    aggregations,

    async treeOrdering(id, data) {
        const url = `/part_categories/tree_ordering/`;
        const response = await HTTP.patch(url, data);
        return response.data;
    },
};