import Router from 'vue-router';
import routes from '../pages/__routes__.js';
import store from '../store';
import { handleRouterBeforeResolve } from '../layouts/TheLayout.vue';


const router = new Router({
    mode: 'history',
    routes,
});

export const authRoutesNames = [
    'signin',
    'password-recovery',
    'password-change',
];

router.beforeEach(async (to, from, next) => {
    let location;
    const isAuth = to.matched.some(route => authRoutesNames.includes(route.name));

    if (!store.state.app.initialized) {
        store.commit('loader/show');

        try {
            await Promise.all([
                store.dispatch('profile/fetch'),
            ]);
        }
        catch (error) {
            const code = (error.response || {}).status;

            if ([401, 403].includes(code) && !isAuth) {
                location = {
                    name: 'signin',
                };
            }
        }
        finally {
            store.commit('loader/hide');
            store.commit('app/setInitialized', true);
        }
    }

    if (location) next(location);
    else next();
});

router.beforeResolve((to, from, next) => {
    handleRouterBeforeResolve(to);

    next();
});

export const safeRouterPush = async location => {
    const toPath = router.resolve(location).route.fullPath;
    const normalize = str => {
        if (!str) return str;

        const lastChar = str[str.length - 1];

        if (lastChar === '/') return str;
        else return str + '/';
    };

    if (normalize(router.currentRoute.fullPath) !== normalize(toPath)) {
        await router.push(location);
    }
};

export default router;