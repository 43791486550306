
<div class="image-cropper">
    <div ref="outerFrame" class="image-cropper__outer-frame">
        <!-- задний фон -->
        <div class="image-cropper__background"></div>
        <!-- изображение на заднем фоне -->
        <div class="image-cropper__background-image-wrap">
            <!-- top left width height -->
            <div
                :style="backgroundImageBoxStyle"
                class="image-cropper__background-image-box"
            >
                <img :src="src" class="image-cropper__image image-cropper__background-image">
            </div>
        </div>
        <!-- дымка над изображением и задним фоном -->
        <div class="image-cropper__haze"></div>
        <!-- зона выреза с overflow и border -->
        <div
            ref="innerFrame"
            :style="innerFrameStyle"
            class="image-cropper__inner-frame"
            :class="{
                'image-cropper__inner-frame_round': round,
            }"
        >
            <!-- яркая часть изображения засинхроненная с фоновым изображением -->
            <!-- top left width height -->
            <div
                ref="frontImageBox"
                :style="frontImageBoxStyle"
                class="image-cropper__front-image-box"
            >
                <!-- top 0 left 0 width 100% height 100% -->
                <img
                    ref="image"
                    :src="src"
                    class="image-cropper__image image-cropper__front-image"
                >
            </div>
        </div>
        <!-- прозрачный слой для захвата и перемещения изображений -->
        <div class="image-cropper__move-layer" @mousedown="onMoveLayerMouseDown">
            <VueTouch
                class="image-cropper__touch-layer"
                @panmove="onPanMove"
                @panend="onPanEnd"
                @pinchstart="onPinchStart"
                @pinchmove="onPinchMove"
            ></VueTouch>
        </div>
    </div>

    <div class="image-cropper__zoom-wrap">
        <div class="image-cropper__zoom-container">
            <div class="image-cropper__zoom-panel">
                <button
                    tabindex="-1"
                    type="button"
                    :disabled="!scale.has || scale.value === scale.min"
                    class="image-cropper__zoom-button image-cropper__zoom-minus"
                    @click="onMinusClick"
                >
                    <UIcon name="minus" class="image-cropper__zoom-button-icon"></UIcon>
                </button>
                <div class="image-cropper__zoom-rails">
                    <div ref="sliderContainer" class="image-cropper__zoom-slider-container">
                        <button
                            ref="slider"
                            class="image-cropper__zoom-slider"
                            :style="sliderStyle"
                            tabindex="-1"
                            type="button"
                            @mousedown="onMouseDownSlider"
                            @touchstart="onTouchSlider"
                        ></button>
                    </div>
                </div>
                <button
                    tabindex="-1"
                    type="button"
                    :disabled="!scale.has || scale.value === scale.max"
                    class="image-cropper__zoom-button image-cropper__zoom-plus"
                    @click="onPlusClick"
                >
                    <UIcon name="plus" class="image-cropper__zoom-button-icon"></UIcon>
                </button>
            </div>
        </div>
    </div>
</div>
