
<UPopupBigTopPosition @close="$emit('close')">
    <UPopupSimpleTemplate>
        <template #body>
            <ImageCropper
                ref="cropper"
                :width="width"
                :height="height"
                :src="src"
                :round="round"
                class="image-cropper"
            ></ImageCropper>
        </template>

        <template #footer>
            <UButtonBlock
                secondary
                @click="$emit('close')"
            >
                Отмена
            </UButtonBlock>

            <UButtonBlock
                primary
                @click="confirm"
            >
                Применить
            </UButtonBlock>
        </template>
    </UPopupSimpleTemplate>
</UPopupBigTopPosition>
