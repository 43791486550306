import { safeRouterPush } from '@/services/router';


export default {
    namespaced: true,

    state: {

    },

    mutations: {

    },

    actions: {
        async handleInitHttpError({}, error) {
            console.log(error);
            const code = (error.response || {}).status;

            if (code === 401) {
                await safeRouterPush({
                    name: 'signin',
                });
            }
        },

        async handleCommonHttpError({}, error) {
            console.log(error);

            const code = (error.response || {}).status;

            if (code === 401) {
                await safeRouterPush({
                    name: 'signin',
                });
            }
        },
    },
};
