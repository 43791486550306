
<div class="u-field-tabs">
    <URadioTabs
        :invalid="invalid"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #label>
            <slot name="label"></slot>
        </template>
    </URadioTabs>

    <UFormFieldError
        :show="invalid"
        class="u-field-tabs__error"
    >
        {{ error }}
    </UFormFieldError>
</div>
