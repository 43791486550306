<template>
    <div class="u-popup-simple-template">
        <div
            v-if="$scopedSlots.header"
            class="u-popup-simple-template__header"
        >
            <slot name="header"></slot>

            <div v-if="$scopedSlots.description" class="u-popup-simple-template__description">
                <slot name="description"></slot>
            </div>
        </div>

        <div ref="body" class="u-popup-simple-template__body">
            <slot name="body"></slot>
        </div>

        <div
            v-if="$scopedSlots.footer"
            class="u-popup-simple-template__footer"
        >
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UPopupSimpleTemplate',
};
</script>

<style>
.u-popup-simple-template {
    display: flex;
    flex-direction: column;
}

.u-popup-simple-template__header {
    position: relative;
    flex-shrink: 0;
    padding: 24px 48px 8px 36px;
}

.u-popup-simple-template__description {
    margin-top: 4px;
}

.u-popup-simple-template__body {
    position: relative;
    padding-top: 24px;
    padding-left: 36px;
    padding-right: 36px;
    line-height: var(--base-lh);
}
.u-popup-simple-template__header + .u-popup-simple-template__body {
    padding-top: 4px;
}
.u-popup-simple-template__body:not(:last-child) {
    padding-bottom: 0;
}

.u-popup-simple-template__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    padding: 24px 36px;
}

.u-popup-simple-template__footer > *:not(:last-child) {
    margin-right: 20px;
}
</style>