
<UInputField
    integer
    v-bind="attrs"
    v-on="listeners"
>
    <template #label>
        <slot name="label"></slot>
    </template>

    <template #append-inner="{ clear }">
        <UTooltip
            v-if="hint || $scopedSlots.hint || $scopedSlots['hint-content']"
            key="hint"
        >
            <template #trigger="{ attrs, handlers, value }">
                <UTooltipIcon
                    :active="value"
                    v-bind="attrs"
                    class="u-input-simple__icon"
                    v-on="handlers"
                ></UTooltipIcon>
            </template>

            <template #content>
                <slot name="hint-content">
                    <UTooltipSimpleTemplate>
                        <slot name="hint">
                            {{ hint }}
                        </slot>
                    </UTooltipSimpleTemplate>
                </slot>
            </template>
        </UTooltip>

        <UIconButton
            v-if="value"
            key="close"
            name="close-16"
            small
            secondary
            tabindex="-1"
            class="u-input-simple__icon"
            @click="clear"
            @mousedown.native.stop=""
        ></UIconButton>
    </template>
</UInputField>
