
<UMenu
    ref="menu"
    showOnFocus
    :shift="false"
    :hideOnBlur="false"
    contentWidthByTrigger
    :visible="initialized"
    transition="expand-fade"
    :hideOnClickContent="false"
    v-on="$listeners"
>
    <template #trigger="trigger">
        <slot v-bind="trigger"></slot>
    </template>

    <li
        v-if="!options.length && hasEmpty"
        class="u-dropdown__option-empty"
    >
        Ничего не найдено
    </li>

    <template v-else>
        <UMenuItem
            v-for="option in options"
            :key="getOptionKey(option)"
            :selected="isOptionSelected(option, selectedOptions)"
            @click="select(option)"
        >
            <slot
                name="label"
                v-bind="{ option }"
            >
                {{ getOptionLabel(option) }}
            </slot>
        </UMenuItem>

        <UMenuItem
            v-if="hasMore"
            class="u-dropdown__option-more"
        >
            <template #content>
                <ButtonText
                    dark
                    dashed
                    secondary
                    tabindex="-1"
                    :loading="loadingMore"
                    @click="emitMore"
                >
                    Показать ещё
                </ButtonText>
            </template>
        </UMenuItem>
    </template>
</UMenu>
