import router, { authRoutesNames } from './router';
import notification from '@/services/notification.js';
import getErrorCode from '@/utils/getErrorCode.js';


export const handleInitHttpError = error => {
    const code = getErrorCode(error);

    if (!code) throw error;

    if (code === 401) {
        const route = router.currentRoute;

        if (authRoutesNames.includes(route.name)) return;

        router.push({
            name: 'signin',
            query: {
                ref: route.fullPath,
            },
        }).then();
    }
    else if (code === 503) {
        // redirect to maintenance mode
    }
    else if (
        code === 403 ||
        code === 404 ||
        code === 429 ||
        code === 500 ||
        code === 502 ||
        code === 504
    ) {
        // redirect to error page
    }
    else {
        throw error;
    }
};

export const handleCommonHttpError = error => {
    if (error.response) {
        const code = getErrorCode(error);
        const data = error.response.data;

        if (code === 400) {
            const { non_field_errors } = data;

            if (non_field_errors) {
                notification.error(non_field_errors[0].message);
            }
            else {
                notification.error('Одно или несколько полей содержат ошибки.');
            }
        }
        else if (code === 404) {
            notification.error('Запрашиваемый адрес не существует.');
        }
        else if (code === 500) {
            let message = 'Внутренняя ошибка сервера, попробуйте повторить попытку позже.';

            if (data.hash) {
                message += ' Код ошибки: ' + data.hash;
            }

            notification.error(message);
        }
        else if (code === 503) {
            // redirect to maintenance mode
        }
    }
    else {
        throw error;
    }
};