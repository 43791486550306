<template>
    <div class="u-popup-base-template">
        <UIconButton
            name="close-24"
            big
            secondary
            class="u-popup-base-template__close"
            @click="$emit('close')"
        ></UIconButton>

        <slot></slot>
    </div>
</template>

<script>
import UIconButton from '@ui/components/UIconButton/UIconButton.vue';
/**
 * Здесь только белая область с бордерами
 */
export default {
    name: 'UPopupBaseTemplate',

    components: { UIconButton },
};
</script>

<style>
.u-popup-base-template {
    position: relative;
    min-width: 124px;
    min-height: 124px;
    background-color: var(--light-c);
    border-radius: var(--border-radius-x2);
    box-shadow: var(--base-shadow);
}

.u-popup-base-template__close {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 1;
}
</style>