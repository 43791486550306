<template>
    <component :is="name" :v-bind="props">
        <slot></slot>
    </component>
</template>

<script>
import Vue from 'vue';


const state = Vue.observable({
    name: 'default',
    props: {},
})

export const handleRouterBeforeResolve = to => {
    const layout = to.matched.reduce((acc, record) => {
        return record.components.default.layout || acc;
    }, 'default');

    state.name = layout.name || layout;
    state.props = layout.props || {};
};

export default {
    name: 'TheLayout',

    // потом переделать на рекурсивный обход pages
    components: {
        default: () => import('./DefaultLayout.vue'),
        auth: () => import('./AuthLayout.vue'),
        admin: () => import('./AdminLayout.vue'),
    },

    computed: {
        name() {
            return state.name;
        },

        props() {
            return state.props;
        },
    },
}
</script>