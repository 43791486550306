
<div class="u-input__wrap">
    <input
        :id="id"
        ref="input"
        :name="name || id"
        :type="type"
        :value="innerValue"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :placeholder="innerPlaceholder"
        class="u-input__el"
        v-on="{
            ...listeners,
            ...handlers,
        }"
    >

    <span
        v-if="visibleMask && (isFocused || innerValue)"
        class="u-input__placeholder"
    ><i class="u-input__placeholder-visible-value">{{ innerValue }}</i>{{ restMask }}</span>
    <span
        v-if="placeholder != null && innerValue === ''"
        class="u-input__placeholder"
        @click="focus"
    >{{ placeholder }}</span>
</div>
