
<section class="u-menu-list__wrap">
    <h6 v-if="$scopedSlots.title" class="u-menu-list__title">
        <slot name="title"></slot>
    </h6>

    <ul class="u-menu-list" v-on="$listeners">
        <slot></slot>
    </ul>
</section>
