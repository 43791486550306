
<div class="u-collection">
    <div class="u-collection__header">
        <div class="u-collection__title">
            {{ title }}
        </div>
        <div v-if="innerValues.length" class="u-collection__count">
            {{ innerValues.length }}
        </div>
        <div class="u-collection__add">
            <UPopup>
                <template #trigger="{ attrs, handlers }">
                    <UButtonRound
                        name="plus-16"
                        primary
                        small
                        v-bind="attrs"
                        v-on="handlers"
                    ></UButtonRound>
                </template>

                <template #default="{ handlers }">
                    <slot
                        name="popup"
                        v-bind="{
                            handlers: {
                                ...handlers,
                                save: onAdd,
                            }
                        }"
                    ></slot>
                </template>
            </UPopup>
        </div>
    </div>

    <div class="u-collection__separator"></div>

    <div v-if="!innerValues.length" class="u-collection__empty">
        Вы еще не добавили ни одного значения
    </div>

    <div v-else class="u-collection__list">
        <div
            v-for="(item, index) in innerValues"
            :key="item.id || item.uuid"
            class="u-collection__item"
        >
            <div class="u-collection__label">
                <slot name="label" v-bind="{ item, index }"></slot>
            </div>
            <div class="u-collection__actions">
                <div class="u-collection__edit u-collection__action-item">
                    <UPopup>
                        <template #trigger="{ attrs, handlers }">
                            <UButtonRound
                                name="edit-16"
                                small
                                v-bind="attrs"
                                v-on="handlers"
                            ></UButtonRound>
                        </template>

                        <template #default="{ handlers }">
                            <slot
                                name="popup"
                                v-bind="{
                                    values: item,
                                    handlers: {
                                        ...handlers,
                                        save: e => onChange(index, item, e),
                                    }
                                }"
                            ></slot>
                        </template>
                    </UPopup>
                </div>

                <div class="u-collection__remove u-collection__action-item">
                    <UButtonRound
                        name="delete-16"
                        small
                        secondary
                        @click="onRemove(index)"
                    ></UButtonRound>
                </div>
            </div>
        </div>
    </div>
</div>
