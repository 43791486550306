
<div class="u-field-checkbox">
    <UCheckbox
        :invalid="invalid"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #label>
            <slot name="label"></slot>
        </template>
    </UCheckbox>

    <UFormFieldError
        :show="invalid"
        class="u-field-checkbox__error"
    >
        {{ error }}
    </UFormFieldError>
</div>
