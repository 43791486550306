
<component
    :is="tag"
    v-bind="attrs"
    class="button-text"
    :class="{
        'button-text_active': active,
        'button-text_loading': loading,
        'button-text_disabled': disabled,
        'button-text_focus': focused,
        'button-text_hover': hovered,
        'button-text_primary': primary,
        'button-text_secondary': secondary,
        'button-text_underline': underline && !loading,
        'button-text_dashed': dashed && !loading,
        'button-text_dark': dark,
        'button-text_black': black,
        'button-text_has-icon': prependIcon || appendIcon,
        'button-text_without-icon': !prependIcon && !appendIcon,
        'button-text_flex': flex,
        'button-text_interactive': tag !== 'span',
    }"
    @click="$emit('click', $event)"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
>
    <USpinner
        v-if="loading"
        class="btn-spin"
        :absoluteCenter="!spinnerLeft"
    ></USpinner>

    <slot
        v-if="prependIcon"
        name="prepend-icon"
    >
        <UIcon
            v-bind="prependIcon"
            class="mr-8 button-text__icon"
        ></UIcon>
    </slot>

    <span class="button-text__label">
        <slot>
            {{ label }}
        </slot>
    </span>

    <slot
        v-if="appendIcon"
        name="append-icon"
    >
        <UIcon
            v-bind="appendIcon"
            class="ml-16 button-text__icon"
        ></UIcon>
    </slot>
</component>
