
<svg
    class="u-icon"
    :class="{
        'u-icon_tiny': tiny,
        'u-icon_small': small,
        'u-icon_big': big,
        'u-icon_huge': huge,
        'u-icon_extra': extra,
        'u-icon_primary': primary,
        'u-icon_white': white,
        'u-icon_secondary': secondary,
        'u-icon_secondary-light': secondary && light,
        'u-icon_yellow': yellow,
        'u-icon_green': green,
        'u-icon_hovered': hovered,
        'u-icon_in-disabled-button': inDisabledBtn,
    }"
    :style="computedSizeStyles"
    v-on="$listeners"
>
    <use :xlink:href="'#' + name"></use>
</svg>
