export default [
    {
        path: '/part-attributes',
        name: 'part-attributes-list',
        component: () => import('./index.vue'),
    },
    {
        path: '/part-attributes/:id',
        name: 'part-attributes-form',
        component: () => import('./_id.vue'),
    }
]