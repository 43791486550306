import useBaseApi from '@/api/useBaseApi.js';


const { get, add, change, remove, aggregations } = useBaseApi('markets');

export default {
    get,
    add,
    change,
    remove,
    aggregations,
};