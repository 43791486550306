export default [
    {
        path: '/part-categories',
        name: 'part-categories-list',
        component: () => import('./index.vue'),
    },
    {
        path: '/part-categories/:id',
        name: 'part-categories-form',
        component: () => import('./_id.vue'),
    }
]