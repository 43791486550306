import { HTTP } from '@/services/http.js';


export default {
    async login(data) {
        const url = '/auth/login/';
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async logout() {
        const url = '/auth/logout/';
        const response = await HTTP.post(url);
        return response.data;
    },

    async passwordRecovery(data) {
        const url = '/auth/password_recovery/';
        const response = await HTTP.post(url, data);
        return response.data;
    },

    async passwordChange(token1, token2, data) {
        const url = `/auth/password_change/${ token1 }/${ token2 }/`;
        const response = await HTTP.post(url, data);
        return response.data;
    },
};