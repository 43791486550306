import useBaseApi from '@/api/useBaseApi.js';


const { get, add, change, remove } = useBaseApi('part_names_cars');

export default {
    get,
    add,
    change,
    remove,
};