
<div class="u-textarea__wrap">
    <div
        v-if="contenteditable"
        :id="id"
        ref="contenteditable"
        role="textbox"
        :name="name"
        :disabled="disabled"
        contenteditable="true"
        class="u-textarea__el u-textarea__contenteditable"
        :class="{
            'u-textarea__el_invalid': invalid,
        }"
        v-on="{
            ...listeners,
            ...handlers,
        }"
    ></div>

    <textarea
        v-else
        :id="id"
        ref="textarea"
        :name="name"
        :value="innerValue"
        :disabled="disabled"
        :rows="rows"
        class="u-textarea__el"
        :class="{
            'u-textarea__el_invalid': invalid,
        }"
        v-on="{
            ...listeners,
            ...handlers,
        }"
    ></textarea>
</div>
