<template>
    <TransitionFade>
        <div v-if="show" class="loader">
            <USpinner big></USpinner>
        </div>
    </TransitionFade>
</template>

<script>
import USpinner from '@ui/components/USpinner/USpinner.vue';
import TransitionFade from '@ui/components/_transitions/TransitionFade.vue';


export default {
    name: 'TheLoader',

    components: { TransitionFade, USpinner },

    computed: {
        show() {
            return this.$store.state.loader.loading;
        },
    },
};
</script>

<style scoped>
.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-c);
    z-index: 100;
}
</style>