<template>
    <UPopupBigTopPosition @close="$emit('close')">
        <UPopupSimpleTemplate>
            <template #body>
                <ImageCropper
                    ref="cropper"
                    :width="width"
                    :height="height"
                    :src="src"
                    :round="round"
                    class="image-cropper"
                ></ImageCropper>
            </template>

            <template #footer>
                <UButtonBlock
                    secondary
                    @click="$emit('close')"
                >
                    Отмена
                </UButtonBlock>

                <UButtonBlock
                    primary
                    @click="confirm"
                >
                    Применить
                </UButtonBlock>
            </template>
        </UPopupSimpleTemplate>
    </UPopupBigTopPosition>
</template>

<script>
import UPopupBigTopPosition from '@ui/components/UPopup/templates/UPopupBigTopPosition.vue';
import UPopupSimpleTemplate from '@ui/components/UPopup/templates/UPopupSimpleTemplate.vue';
import ImageCropper from '@/components/ImageCropper.vue';
import UButtonBlock from '@ui/components/UButton/UButtonBlock.vue';


export default {
    name: 'FileLoaderCropperPopup',

    components: {
        UButtonBlock,
        ImageCropper,
        UPopupSimpleTemplate,
        UPopupBigTopPosition,
    },

    props: {
        src: String,
        width: Number,
        height: Number,
        round: Boolean,
    },

    methods: {
        async confirm() {
            const file = await this.$refs.cropper.crop();
            this.$emit('cropped', file);
        },
    },
};
</script>

<style scoped>
.image-cropper {
    margin-top: 24px;
}
</style>