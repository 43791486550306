
<div class="u-radio__list">
    <div
        v-for="option in options"
        :key="getOptionKey(option)"
        class="u-radio__item"
        :class="{
            'u-radio__item_checked': optionComparator(innerValue, option),
        }"
    >
        <input
            :id="getOptionKey(option) + id"
            type="radio"
            :value="reduce(option)"
            :checked="optionComparator(innerValue, option)"
            class="visually-hidden u-radio__input"
            v-on="{
                ...handlers,
                ...listeners,
            }"
        >

        <slot
            :id="getOptionKey(option) + id"
            name="label"
            :option="option"
            :label="getOptionLabel(option)"
        >
            <label
                :for="getOptionKey(option) + id"
                class="u-radio__label"
            >
                {{ getOptionLabel(option) }}
            </label>
        </slot>
    </div>
</div>
