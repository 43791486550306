
<div class="u-select">
    <UDropdown
        ref="dropdown"
        :options="filteredOptions"
        :hasMore="hasMore"
        :loadingMore="loadingMore"
        :initialized="initialized"
        :optionKey="optionKey"
        :optionLabel="optionLabel"
        :getOptionKey="getOptionKey"
        :getOptionLabel="getOptionLabel"
        @select="onSelect"
        @open="opened = true; emitOpen()"
        @close="opened = false; emitClose()"
        v-on="listeners"
    >
        <template #default="{ handlers }">
            <UInputField
                ref="input"
                :value="inputValue"
                :small="small"
                :disabled="disabled"
                clearInputOnBlur
                @input="onInput"
                @change="onChange"
                @focus="focused = true; $emit('focus', $event)"
                @blur="focused = false; $emit('blur', $event)"
                @mouseenter.native="hovered = true"
                @mouseleave.native="hovered = false"
                @focusin.native="handlers.focusin"
            >
                <template v-if="label" #label-element="{ attrs }">
                    <ULabel
                        v-bind="attrs"
                        :active="focused || !!innerValue || !!inputValue"
                    >
                        {{ label }}
                    </ULabel>
                </template>

                <template #append-inner>
                    <USpinner
                        v-if="loading"
                        small
                    ></USpinner>

                    <UIconButton
                        v-else-if="innerValue && active"
                        name="close-16"
                        small
                        secondary
                        tabindex="-1"
                        aria-label="Очистить поле"
                        class="u-select__clear"
                        @click="onClickClear"
                        @focusin.native.stop=""
                    ></UIconButton>

                    <UIconButton
                        v-else
                        name="arrow-down-16"
                        small
                        secondary
                        tabindex="-1"
                        :aria-label="opened ? 'Показать список значений' : 'Скрыть список значений'"
                        class="u-select__arrow"
                        :class="{
                            'u-select__arrow_up': opened,
                        }"
                        @click="toggle"
                        @focusin.native.stop=""
                    ></UIconButton>
                </template>
            </UInputField>
        </template>

        <template #label="payload">
            <slot name="list-label" v-bind="payload">
                <slot name="label" :item="payload.option">
                    {{ getOptionLabel(payload.option) }}
                </slot>
            </slot>
        </template>
    </UDropdown>

    <span
        v-if="!inputValue && innerValue"
        class="u-select__value"
        :class="{
            'u-select__value_inactive': focused,
        }"
        @click="onClickValue"
    >
        <slot name="value-label" :value="innerValue">
            <slot name="label" :item="innerValue">
                {{ getOptionLabel(innerValue) }}
            </slot>
        </slot>
    </span>
</div>
