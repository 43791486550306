
<div class="u-popup-base-template">
    <UIconButton
        name="close-24"
        big
        secondary
        class="u-popup-base-template__close"
        @click="$emit('close')"
    ></UIconButton>

    <slot></slot>
</div>
