import generationRoutes from './_generation/__routes__.js';


export default [
    ...generationRoutes,
    {
        path: '/part-links',
        name: 'part-links',
        component: () => import('./index.vue'),
    },
]