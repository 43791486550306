
<div
    class="u-spinner"
    :class="{
        'u-spinner_small': small,
        'u-spinner_big': big,
        'u-spinner_light': light,
        'u-spinner_dark': dark,
        'u-spinner_center': center,
        'u-spinner_absolute-center': absoluteCenter,
    }"
>
    <UIcon
        name="spinner"
        class="u-spinner__icon"
    ></UIcon>
</div>
