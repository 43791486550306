
<UInputField
    v-bind="attrs"
    v-on="listeners"
>
    <template #label>
        <slot name="label"></slot>
    </template>

    <template #append-inner="{ clear }">
        <UIconButton
            v-if="value"
            key="close"
            name="close-16"
            small
            secondary
            tabindex="-1"
            @click="clear"
            @mousedown.native.stop=""
        ></UIconButton>
    </template>
</UInputField>
