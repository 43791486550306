<template>
    <div
        class="file-loader"
        :class="{
            'file-loader_drag': drag,
            'file-loader_empty': isEmpty,
            'file-loader_invalid': invalid,
        }"
        @dragenter.prevent.stop="handleDrag"
        @dragover.prevent.stop="handleDrag"
        @dragleave.prevent.stop="handleOutDrag"
        @drop.prevent="handleDrop"
    >
        <span
            v-if="label"
            class="file-loader__field-label"
            :class="{
                'file-loader__field-label_offset': innerItems.length,
                'file-loader__field-label_invalid': invalid,
            }"
        >{{ label }}</span>

        <div
            v-if="innerItems.length"
            class="file-loader__loaded-items-list"
        >
            <div
                v-for="(obj, index) in innerItems"
                :key="obj.__id || index"
                class="file-loader__loaded-item"
            >
                <div class="file-loader__loaded-item-preview-wrap">
                    <img
                        v-if="isImage"
                        :src="obj.src || ($links.uploads + getItemSrc(obj.item))"
                        :alt="obj.item.name"
                        class="file-loader__loaded-item-preview-img"
                        :class="{
                            'file-loader__loaded-item-preview-img_loading': obj.loading,
                        }"
                    >
                    <div
                        v-else
                        class="file-loader__loaded-item-preview-icon-wrap"
                    >
                        <UIcon
                            name="file-loader"
                            class="file-loader__loaded-item-preview-icon"
                        ></UIcon>
                        <span class="file-loader__loaded-item-preview-icon-extension">{{ obj.item.extension }}</span>
                    </div>

                    <div class="file-loader__progress-bar-wrap">
                        <LoaderProgressBar
                            v-if="obj.loading"
                            :progress="obj.loadingProgress"
                            class="file-loader__progress-bar"
                        ></LoaderProgressBar>
                    </div>
                </div>

                <div class="file-loader__loaded-item-info">
                    <p class="file-loader__text-block file-loader__file-description">
                        <ButtonText
                            :pseudo="!obj.item[urlKey]"
                            :href="$links.uploads + obj.item[urlKey]"
                            :underline="!!obj.item[urlKey]"
                            target="_blank"
                            download
                            class="file-loader__file-description-button"
                        >
                            <span
                                :title="obj.item.name"
                                class="file-loader__file-name"
                            >{{ clearName(obj.item.name, obj.item.extension) }}</span>
                            <span
                                v-if="obj.item.extension"
                                class="file-loader__file-extension"
                            >.{{ obj.item.extension }}</span>
                        </ButtonText>

                        <span class="file-loader__file-size">({{ getSizeString(obj.item[sizeKey]) }})</span>
                    </p>
                    <p
                        v-if="obj.errors.size"
                        class="file-loader__text-block file-loader__file-error"
                    >
                        Максимальный размер файла {{ maxSize }} МБ
                    </p>
                    <p
                        v-else-if="obj.errors.extension"
                        class="file-loader__text-block file-loader__file-error"
                    >
                        Можно загружать только {{ normalizedAccept.join(', ') }}
                    </p>
                    <p
                        v-else-if="obj.errors.resolution"
                        class="file-loader__text-block file-loader__file-error"
                    >
                        Минимальное разрешение изображения {{ width }}x{{ height }} рх
                    </p>
                </div>

                <ButtonIcon
                    :icon="{
                        name: 'cross',
                        small: true,
                        secondary: true,
                        hovered: true
                    }"
                    :secondary="false"
                    class="file-loader__delete-btn"
                    @click="deleteItem(index)"
                ></ButtonIcon>
            </div>
        </div>

        <label
            v-if="innerItems.length < maxItems"
            :for="'file-loader-input_' + id"
            class="file-loader__action-label"
            :class="{
                'file-loader_empty__action-label': innerItems.length === 0
            }"
        >
            <span
                class="file-loader__text-block file-loader__placeholder"
                :class="{
                    'file-loader__placeholder_invalid': isEmpty && invalid,
                }"
            >
                <ButtonText
                    underline
                    primary
                    pseudo
                    class="d-inline-block"
                >Выберите</ButtonText> {{ chooseString }}
            </span>
        </label>

        <span
            class="file-loader__limitations-text"
            :class="{
                'file-loader__limitations-text_invalid': isEmpty && invalid,
            }"
        >
            {{ info }}
        </span>

        <ButtonText
            v-if="hasInvalidFiles"
            dashed
            dark
            secondary
            class="mt-16"
            @click="removeInvalidFiles"
        >
            Удалить все {{ isImage ? 'изображения' : 'файлы' }} с ошибками
        </ButtonText>

        <input
            :id="'file-loader-input_' + id"
            ref="input"
            type="file"
            name="file-loader"
            class="visually-hidden file-loader__input"
            :accept="acceptComputed"
            :multiple="isMulti ? 'multiple' : null"
            @change="addFiles($event.target.files)"
        >

        <UPopup ref="cropper">
            <FileLoaderCropperPopup
                :src="cropper.src"
                :width="parseInt(width)"
                :height="parseInt(height)"
                @close="onCloseCropper"
                @cropped="onCropped"
            ></FileLoaderCropperPopup>
        </UPopup>
    </div>
</template>

<script>
// services
import { HTTP } from '@/services/http.js';
import { handleCommonHttpError } from '@/services/error.js';
// utils
import equals from '@ui/utils/equals.js';
import genId from "@ui/utils/genId.js";
import setId from '@ui/components/UForm/utils/setId.js';
// components
import UIcon from '@ui/components/UIcon/UIcon.vue';
import ButtonIcon from '@ui/components/UButton/UButtonIcon.vue';
import LoaderProgressBar from '@/components/LoaderProgressBar.vue';
import ButtonText from '@ui/components/UButton/UButtonText.vue';
import UPopup from '@ui/components/UPopup/UPopup.vue';
import FileLoaderCropperPopup from '@/components/FileLoaderCropperPopup.vue';
import deepClone from '@ui/utils/deepClone.js';


const ACCEPT_DICT = {
    'svg': 'image/svg+xml',
    'docx': 'application/msword',
    'doc': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'xlsx': 'application/vnd.ms-excel',
    'xls': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'pdf': 'application/pdf',
    'png': 'image/png',
    'jpg': 'image/jpg',
    'jpeg': 'image/jpeg',
};

const invertedAcceptDict = Object.entries(ACCEPT_DICT).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
}, {});


export default {
    name: 'FileLoader',

    components: {
        FileLoaderCropperPopup,
        UPopup,
        ButtonText,
        LoaderProgressBar,
        ButtonIcon,
        UIcon,
    },

    model: {
        prop: 'value',
        event: 'change',
    },

    props: {
        id: {
            type: String,
            default: () => genId(),
        },

        label: {
            type: String,
            default: '',
        },

        value: {
            type: [Object, Number, Array],
        },

        maxItems: {
            type: Number,
            default: 1,
        },

        maxSize: {
            type: Number,
            default: 3, // Mb
        },

        file: Boolean,

        image: {
            type: Boolean,
            default: true,
        },

        accept: {
            type: [String, Array],
            default() {
                return  ['jpg', 'jpeg'];
            },
        },

        width: [String, Number],
        height: [String, Number],

        invalid: Boolean,

        api: {
            type: String,
            required: true,
        },

        // Количество файлов в очереди загрузки
        threadsCount: {
            type: Number,
            default: 3,
        },

        idKey: {
            type: String,
            default: 'id',
        },

        getItemId: {
            type: Function,
            default(item) {
                if (item) {
                    if (typeof item === 'object') {
                        return item[this.idKey];
                    }
                    else {
                        return item;
                    }
                }
                else {
                    return item;
                }
            },
        },

        compareItems: {
            type: Function,
            default(arr1, arr2) {
                if (arr1 && arr2) {
                    const keys1 = arr1.map(this.getItemId);
                    const keys2 = arr2.map(this.getItemId);

                    return equals(keys1, keys2);
                }

                return true;
            },
        },

        widthKey: {
            type: String,
            default: 'width',
        },

        heightKey: {
            type: String,
            default: 'height',
        },

        extensionKey: {
            type: String,
            default: 'extension',
        },

        sizeKey: {
            type: String,
            default: 'size',
        },

        nameKey: {
            type: String,
            default: 'name',
        },

        // = url для файла
        srcKey: {
            type: String,
            default: 'src',
        },

        // для скачивания
        urlKey: {
            type: String,
            default: 'url',
        },

        thumbnailsKey: {
            type: String,
            default: 'thumbnails',
        },

        thumbnailName: String,

        getItemSrc: {
            type: Function,
            default(item) {
                if (item[this.srcKey]) {
                    return item[this.srcKey];
                }

                if (this.isFile) {
                    return item.url;
                }

                if (this.isImage) {
                    if (item[this.thumbnailsKey] && item[this.thumbnailsKey][this.thumbnailName]) {
                        return item[this.thumbnailsKey][this.thumbnailName];
                    }
                    else {
                        return item.original_url;
                    }
                }

                return null;
            },
        },

        itemComparator: {
            type: Function,
            default(item1, item2) {
                return item1.__id === item2.__id
                    && this.getItemId(item1) === this.getItemId(item2);
            },
        },
    },

    data() {
        return {
            drag: false,
            src: '',
            cropper: {
                src: '',
                resolve: null,
            },

            // содержит ошибочные, загруженные и загружаемые
            innerItems: [],
            // innerErrors: [],
        };
    },

    computed: {
        isSingle() {
            return this.maxItems === 1;
        },

        isMulti() {
            return this.maxItems > 1;
        },

        hasRatio() {
            return !!this.width && !!this.height;
        },

        ratio() {
            if (this.hasRatio) {
                return parseInt(this.width) / parseInt(this.height);
            }

            return null;
        },

        innerErrors() {
            if (!this.innerItems.length) return [];

            const errors = this.innerItems.map(item => {
                const err = [];

                if (item) {
                    if (item.errors.size) {
                        err.push({
                            code: 'size',
                            message: `Максимальный размер файла ${ this.maxSize } МБ`,
                        });
                    }

                    if (item.errors.resolution) {
                        err.push({
                            code: 'resolution',
                            message: `Минимальное разрешение изображения ${ this.width }x${ this.height } рх`,
                        });
                    }

                    if (item.errors.extension) {
                        err.push({
                            code: 'extension',
                            message: 'Можно загружать только ' + this.normalizedAccept.join(', '),
                        });
                    }
                }

                return err;
            });

            if (this.isMulti) {
                return errors;
            }

            return errors[0];
        },

        normalizedInnerErrors() {
            if (this.isMulti) {
                return this.innerErrors.some(item => item.length) ? [{
                    code: 'invalid',
                    message: 'Одно или несколько полей содержат ошибки',
                }] : [];
            }

            return this.innerErrors;
        },

        hasInvalidFiles() {
            return this.innerItems.map(item => {
                const { size, resolution, extension } = item.errors;
                return size || resolution || extension;
            }).some(value => value);
        },

        isFile() {
            return !!this.file;
        },

        isImage() {
            return !!this.image && !this.file;
        },

        normalizedAccept() {
            return typeof this.accept === 'string'
                ? this.accept.split(',').map(name => name.trim())
                : this.accept;
        },

        acceptComputed() {
            return this.normalizedAccept.map(name => ACCEPT_DICT[name.toLowerCase()]).join(',');
        },

        acceptString() {
            return this.normalizedAccept.join(', ').toUpperCase();
        },

        chooseString() {
            let str = '';
            str += this.isMulti || this.maxItems > 1
                ? this.isFile ? 'файлы' : 'изображения'
                : this.isFile ? 'файл' : 'изображение';
            str += ' или перетащите ';
            str += this.isMulti || this.maxItems > 1 ? 'их' : 'его';
            str += ' сюда';
            return str;
        },

        info() {
            let str = '';

            if (this.width && this.height) {
                str += 'мин. ' + this.width + 'x' + this.height + ' px; ';
            }

            if (this.isMulti) {
                str += 'не более ' + this.maxItems + ' шт.; ';
            }

            if (this.normalizedAccept.length) {
                str += 'только ' + this.acceptString + '; ';
            }

            str = str + 'макс. размер ' + this.maxSize + ' МБ';
            return str.charAt(0).toUpperCase() + str.slice(1);
        },

        normalizedPropItems() {
            return Array.isArray(this.value)
                ? this.value
                : this.value
                    ? [this.value]
                    : [];
        },

        outputItems() {
            return this.innerItems.map(item => item.item);
        },

        outputItemsIds() {
            return this.outputItems.map(item => item.__id);
        },

        emitValue() {
            if (this.isMulti) {
                return this.outputItems;
            }
            else {
                return this.outputItems[0];
            }
        },

        queue() {
            // очередь для поочерёдной загрузки
            return this.innerItems
                .map((item, index) => {
                    item.index = index;
                    return item;
                })
                .filter(item => {
                    return !item.loaded
                        && !item.loading
                        && !item.errors.size
                        && !item.errors.extension
                        && !item.errors.resolution;
                });
        },

        loadingItems() {
            return this.innerItems.filter(item => item.loading);
        },

        isEmpty() {
            return !this.innerItems.length;
        },
    },

    watch: {
        outputItems: {
            handler(outputItems) {
                let equal = true;

                if (equal) {
                    equal = outputItems.length === this.normalizedPropItems.length;
                }

                if (equal) {
                    for (let item of outputItems) {
                        if (equal) {
                            equal = this.normalizedPropItems.some(value => this.itemComparator(item, value));
                        }
                        else {
                            break;
                        }
                    }
                }

                if (!equal) {
                    const target = this;
                    const value = this.emitValue;
                    this.$emit('change', { target, value });
                    // т. к. change в FormField очищает ошибки,
                    // приходится пробрасывать ошибки снова
                    this.$emit('error', deepClone(this.normalizedInnerErrors));
                }
            },
        },

        queue(queue) {
            if (queue.length) {
                const emptySlotsCount = this.threadsCount - this.loadingItems.length;

                if (emptySlotsCount) {
                    this.preUploadFile(queue[0]);
                }
            }
        },

        normalizedPropItems: {
            handler(items) {
                if ((!items.length || items.some(item => typeof item !== 'object')) && this.innerItems.length) {
                    this.innerItems = [];
                    return;
                }

                const ids = items.map(item => item.__id);

                if (!equals(ids, this.outputItemsIds)) {
                    this.innerItems = items.map(item => this.modifyPropItem(item));
                }
            },

            immediate: true,
        },

        normalizedInnerErrors: {
            handler(errors) {
                this.$emit('error', deepClone(errors));
            },
        },
    },

    methods: {
        modifyPropItem(obj) {
            const item = setId(obj, obj.__id || this.getItemId(obj));
            const meta = this.createMeta(item, true);
            return { item, ...meta };
        },

        async addFiles(files) {
            const currentCount = this.innerItems.length;
            const totalCount = currentCount + files.length;

            if (totalCount > this.maxItems) {
                this.$emit('error', [{
                    code: 'max_length',
                    message: 'Максимальное кол-во файлов ' + this.maxItems + ' шт.',
                }]);
            }

            const limit = Math.min(files.length, this.maxItems - currentCount);

            for (let i = 0; i < limit; i++) {
                const file = files[i];

                if (file) {
                    const normalizedFile = await this.normalizeInputFile(file);

                    if (normalizedFile) {
                        this.innerItems.splice(this.innerItems.length, 0, normalizedFile);
                    }
                }
            }
        },

        async normalizeInputFile(file) {
            const item = await this.createItem(file);
            if (!item) return;
            setId(item);
            const meta = this.createMeta(item);
            return { file, item, ...meta };
        },

        async createItem(file) {
            let url = URL.createObjectURL(file);
            let name;
            let width;
            let height;
            let extension;

            if (this.isImage) {
                const resolution = await this.getResolution(url);

                width = resolution.width;
                height = resolution.height;

                if (this.hasRatio) {
                    const ratio = width / height;

                    if (this.ratio !== ratio) {
                        file = await this.crop(url);

                        if (!file) return;

                        url = URL.createObjectURL(file);

                        const resolution = await this.getResolution(url);

                        width = resolution.width;
                        height = resolution.height;
                    }
                }
            }

            extension = this.getExtension(file);
            name = this.getName(file) + '.' + extension;

            return {
                blob: url,
                [this.srcKey]: url,
                [this.sizeKey]: file.size,
                extension,
                name,
                width,
                height,
            };
        },

        async crop(src) {
            return new Promise(resolve => {
                this.cropper.src = src;
                this.cropper.resolve = resolve;
                this.$refs.cropper.open();
            });
        },

        onCropped(file) {
            this.cropper.resolve && this.cropper.resolve(file);
            this.$refs.cropper.close();
        },

        onCloseCropper() {
            this.cropper.resolve && this.cropper.resolve();
            this.$refs.cropper.close();
        },

        getName(file) {
            return file.name.substring(0, file.name.lastIndexOf('.'));
        },

        getExtension(file) {
            const type = file.type;
            return invertedAcceptDict[type];
        },

        getResolution(url) {
            return new Promise(resolve => {
                const imageElement = new Image();

                imageElement.src = url;

                imageElement.onload = () => {
                    resolve({
                        width: imageElement.width,
                        height: imageElement.height,
                    });
                };
            });
        },

        createMeta(item, loaded = false) {
            const errors = {
                size: loaded ? false : this.getSizeError(item[this.sizeKey]),
                extension: loaded ? false : this.getExtensionError(item.extension),
            };

            if (this.isImage) {
                errors.resolution = loaded ? false : this.getResolutionError(item);
            }

            return {
                loaded,
                loading: false,
                loadingProgress: null,
                errors,
                src: item.blob,
            };
        },

        getSizeError(size) {
            return size > this.maxSize * 1024 * 1024;
        },

        getExtensionError(extension) {
            // mime type?
            return !this.normalizedAccept.includes(extension.toLowerCase());
        },

        getResolutionError({ width, height }) {
            return (this.height && this.height > height)
                || (this.width && this.width > width);
        },

        async preUploadFile(item) {
            item.loading = true;

            const response = await this.uploadFile(item);

            if (response) {
                delete item.source;
                delete item.file;
                item.loading = false;
                item.loaded = true;
                item.loadingProgress = null;
                item.item = setId(response, item.__id);
                const index = this.outputItemsIds.indexOf(item.__id);
                this.innerItems.splice(index, 1, item);
            }
        },

        async uploadFile(item) {
            const data = new FormData();
            data.append('file', item.file, item.file.name);

            item.loadingProgress = 0;
            item.source = HTTP.CancelToken.source();

            try {
                const response = await HTTP.post(this.api, data, {
                    onUploadProgress: (progressEvent) => {
                        item.loadingProgress = parseInt((progressEvent.loaded / progressEvent.total) * 100);
                    },
                    cancelToken: item.source.token,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                response.data.file_size = response.data.file_size * 1024;
                return response.data;
            }
            catch (error) {
                handleCommonHttpError(error);
            }
        },

        getSizeString(size) {
            if (typeof size === 'number') {
                const kb = (size / 1024).toFixed(2);

                if (kb < 1024) {
                    return kb.toLocaleString('ru-RU', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                    }) + ' KБ';
                }
                else {
                    return (kb / 1024).toFixed(2).toLocaleString('ru-RU', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                    }) + ' МБ';
                }
            }

            return size;
        },

        deleteItem(index) {
            if (this.innerItems[index].source) {
                this.innerItems[index].source.cancel();
            }

            this.innerItems.splice(index, 1);
        },

        handleDrag() {
            this.drag = true;
        },

        handleDrop(event) {
            this.drag = false;
            const files = event.dataTransfer.files;
            this.addFiles(files);
        },

        handleOutDrag() {
            this.drag = false;
        },

        clearName(name, extension) {
            if (!extension) return name;

            const re = new RegExp('\\.' + extension + '(?!.*\\.' + extension + ')', 'gi');

            return name.replace(re, '');
        },

        removeInvalidFiles() {
            this.innerItems = this.innerItems.filter(item => {
                return !item.errors.size
                    && !item.errors.extension
                    && !item.errors.resolution;
            });
        },
    },
};
</script>

<style>
.file-loader {
    position: relative;
    padding: 16px;
    border: 2px dashed var(--border-dark-c);
    border-radius: var(--border-radius);
    transition: border-color var(--transition), background-color var(--transition);
}
.file-loader.file-loader_empty:hover,
.file-loader.file-loader_empty.file-loader_drag {
    border-color: var(--border-dark-active-c);
}
.file-loader.file-loader_invalid {
    border-color: var(--error-medium-color);
}
.file-loader.file-loader_invalid.file-loader_empty {
    background-color: var(--error-brightest-color);
    border-color: var(--error-lightest-color);
}
.file-loader.file-loader_invalid:hover,
.file-loader.file-loader_invalid.file-loader_drag {
    border-color: var(--error-medium-color);
}

.file-loader__field-label {
    display: block;
    max-width: calc(100% - 32px);
    margin-bottom: 8px;
    font-size: var(--base-fz);
    line-height: 20px;
    color: var(--font-secondary-color);
    background-color: transparent;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    cursor: text;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: top var(--transition-fast), font-size var(--transition-fast), color var(--transition);
}
.file-loader.file-loader_empty:hover .file-loader__field-label,
.file-loader.file-loader_empty.file-loader_drag .file-loader__field-label {
    color: var(--font-secondary-dark-color);
}
.file-loader__field-label.file-loader__field-label_invalid {
    color: var(--error-medium-color);
}
.file-loader.file-loader_empty:hover .file-loader__field-label.file-loader__field-label_invalid,
.file-loader.file-loader_empty.file-loader_drag .file-loader__field-label.file-loader__field-label_invalid {
    color: var(--error-main-color);
}

.file-loader__field-label_offset {
    position: absolute;
    top: -9px;
    left: 14px;
    z-index: 3;
    padding: 0 4px;
    font-size: 12px;
    line-height: 16px;
    background-color: var(--light-c);
}

.file-loader__action-label {
    margin-top: 16px;
}

.file-loader_empty__action-label {
    margin-top: 0;
}

.file-loader__loaded-items-list {
    margin-top: 4px;
    margin-bottom: 4px;
}

.file-loader__loaded-item {
    position: relative;
    display: flex;
    align-items: center;
}

.file-loader__loaded-item:not(:last-child) {
    margin-bottom: 16px;
}

.file-loader__loaded-item-preview-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    margin-right: 16px;
    flex-shrink: 0;
    border-radius: var(--border-radius);
    overflow: hidden;
}
@media (max-width: 767px) {
    .file-loader__loaded-item-preview-wrap {
        width: 48px;
        height: 48px;
    }
}

.file-loader__loaded-item-preview-img {
    display: block;
    min-height: 100%;
    min-width: 100%;
    max-width: initial;
    object-fit: cover;
    object-position: center;
    font-size: 0;
}

.file-loader__loaded-item-preview-img_loading {
    opacity: .3;
}

.file-loader__loaded-item-preview-icon-wrap {
    position: relative;
}

.file-loader__loaded-item-preview-icon {
    width: 60px;
    height: 60px;
}

.file-loader__loaded-item-preview-icon-extension {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 14px;
    line-height: var(--small-lh);
    font-family: var(--f-bold);
    color: var(--font-secondary-light-color);
    text-align: center;
}

.file-loader__loaded-item-info {
    width: calc(100% - 76px - 30px);
}

.file-loader__delete-btn {
    margin-right: -10px;
    margin-left: auto;
}

.file-loader__progress-bar-wrap {
    position: absolute;
    bottom: 8px;
    left: 10px;
    z-index: 3;
}
@media (max-width: 767px) {
    .file-loader__progress-bar-wrap {
        bottom: 4px;
        left: 4px;
    }
}

.file-loader__progress-bar {
    width: 40px;
}

.file-loader__text-block {
    font-size: var(--base-fz);
    line-height: var(--small-lh);
}
.file-loader__text-block:not(:first-child) {
    margin-top: 4px;
}

.file-loader__file-description {
    min-width: 0;
    display: grid;
    grid-template-columns: auto max-content;
    align-items: center;
    justify-content: flex-start;
}

.file-loader__file-description-button {
    min-width: 0;
}

.file-loader__file-description-button .button-text__label {
    display: flex;
}

.file-loader__file-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file-loader__file-extension {
    flex-shrink: 0;
    text-transform: lowercase;
}

.file-loader__file-size {
    margin-left: 4px;
    flex-shrink: 0;
    color: var(--font-secondary-color);
}

.file-loader__file-error {
    color: var(--primary-color);
    font-size: var(--small-fz);
}

.file-loader__placeholder {
    color: var(--font-secondary-color);
    transition: color var(--transition);
}
.file-loader__placeholder.file-loader__placeholder_invalid {
    color: var(--error-medium-color);
}
.file-loader.file-loader_empty:hover .file-loader__placeholder,
.file-loader.file-loader_empty.file-loader_drag .file-loader__placeholder {
    color: var(--font-secondary-dark-color);
}
.file-loader.file-loader_empty:hover .file-loader__placeholder.file-loader__placeholder_invalid,
.file-loader.file-loader_empty.file-loader_drag .file-loader__placeholder.file-loader__placeholder_invalid {
    color: var(--error-main-color);
}

.file-loader__limitations-text {
    display: block;
    margin-top: 16px;
    font-size: var(--small-fz);
    color: var(--font-secondary-color);
    transition: color var(--transition);
}
.file-loader__action-label ~ .file-loader__limitations-text {
    margin-top: 4px;
}
.file-loader__limitations-text_invalid {
    color: var(--error-medium-color);
}
.file-loader.file-loader_empty:hover .file-loader__limitations-text,
.file-loader.file-loader_empty.file-loader_drag .file-loader__limitations-text {
    color: var(--font-secondary-dark-color);
}
.file-loader.file-loader_empty:hover .file-loader__limitations-text.file-loader__limitations-text_invalid,
.file-loader.file-loader_empty.file-loader_drag .file-loader__limitations-text.file-loader__limitations-text_invalid {
    color: var(--error-main-color);
}
</style>