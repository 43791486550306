
<div class="u-field-email">
    <UInputEmail
        :invalid="invalid"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #label>
            <slot name="label"></slot>
        </template>
    </UInputEmail>

    <UFormFieldError
        :show="invalid"
        class="u-field-email__error"
    >
        {{ error }}
    </UFormFieldError>
</div>
