<template>
    <div class="app">
        <TheLoader></TheLoader>
        <TheNotification></TheNotification>
        <TheLayout>
            <RouterView></RouterView>
        </TheLayout>
    </div>
</template>

<script>
import TheLayout from '@/layouts/TheLayout.vue';
import TheLoader from '@/components/TheLoader.vue';
import TheNotification from '@/components/TheNotification.vue';


export default {
    name: 'App',

    components: {
        TheNotification,
        TheLoader,
        TheLayout,
    },
};
</script>

<style scoped>
.app {
    position: absolute;
    width: 100%;
    height: 100%;
}
</style>