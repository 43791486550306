
<URadio
    class="u-radio-tabs"
    v-bind="$attrs"
    v-on="listeners"
    @change="onChange"
>
    <template #label="{ id, label }">
        <label :for="id" class="u-radio-tabs__label">
            {{ label }}
        </label>
    </template>
</URadio>
