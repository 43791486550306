<template>
    <div class="image-cropper">
        <div ref="outerFrame" class="image-cropper__outer-frame">
            <!-- задний фон -->
            <div class="image-cropper__background"></div>
            <!-- изображение на заднем фоне -->
            <div class="image-cropper__background-image-wrap">
                <!-- top left width height -->
                <div
                    :style="backgroundImageBoxStyle"
                    class="image-cropper__background-image-box"
                >
                    <img :src="src" class="image-cropper__image image-cropper__background-image">
                </div>
            </div>
            <!-- дымка над изображением и задним фоном -->
            <div class="image-cropper__haze"></div>
            <!-- зона выреза с overflow и border -->
            <div
                ref="innerFrame"
                :style="innerFrameStyle"
                class="image-cropper__inner-frame"
                :class="{
                    'image-cropper__inner-frame_round': round,
                }"
            >
                <!-- яркая часть изображения засинхроненная с фоновым изображением -->
                <!-- top left width height -->
                <div
                    ref="frontImageBox"
                    :style="frontImageBoxStyle"
                    class="image-cropper__front-image-box"
                >
                    <!-- top 0 left 0 width 100% height 100% -->
                    <img
                        ref="image"
                        :src="src"
                        class="image-cropper__image image-cropper__front-image"
                    >
                </div>
            </div>
            <!-- прозрачный слой для захвата и перемещения изображений -->
            <div class="image-cropper__move-layer" @mousedown="onMoveLayerMouseDown">
                <VueTouch
                    class="image-cropper__touch-layer"
                    @panmove="onPanMove"
                    @panend="onPanEnd"
                    @pinchstart="onPinchStart"
                    @pinchmove="onPinchMove"
                ></VueTouch>
            </div>
        </div>

        <div class="image-cropper__zoom-wrap">
            <div class="image-cropper__zoom-container">
                <div class="image-cropper__zoom-panel">
                    <button
                        tabindex="-1"
                        type="button"
                        :disabled="!scale.has || scale.value === scale.min"
                        class="image-cropper__zoom-button image-cropper__zoom-minus"
                        @click="onMinusClick"
                    >
                        <UIcon name="minus" class="image-cropper__zoom-button-icon"></UIcon>
                    </button>
                    <div class="image-cropper__zoom-rails">
                        <div ref="sliderContainer" class="image-cropper__zoom-slider-container">
                            <button
                                ref="slider"
                                class="image-cropper__zoom-slider"
                                :style="sliderStyle"
                                tabindex="-1"
                                type="button"
                                @mousedown="onMouseDownSlider"
                                @touchstart="onTouchSlider"
                            ></button>
                        </div>
                    </div>
                    <button
                        tabindex="-1"
                        type="button"
                        :disabled="!scale.has || scale.value === scale.max"
                        class="image-cropper__zoom-button image-cropper__zoom-plus"
                        @click="onPlusClick"
                    >
                        <UIcon name="plus" class="image-cropper__zoom-button-icon"></UIcon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import throttle from '@ui/utils/throttle.js';
import UIcon from '@ui/components/UIcon/UIcon.vue';


const length = value => {
    return value ? value + 'px' : null;
};

let prevPanX = 0;
let prevPanY = 0;
let startImageWidth = 0;

export default {
    name: 'ImageCropper',

    components: { UIcon },

    props: {
        width: Number, // inner frame
        height: Number, // inner frame
        round: Boolean,
        src: String,
    },

    data() {
        return {
            innerFrame: {
                top: 0,
                left: 0,
                width: 0,
                height: 0,
            },

            backgroundImageBox: {
                top: null,
                left: null,
                width: 0,
                height: 0,
            },

            originalImageWidth: 0,
            originalImageHeight: 0,

            scale: {
                has: true,
                value: 1,
                index: 0,
                steps: [0, 1],
                min: 0,
                max: 1,
            },

            oldOuterFrame: {
                width: null,
                height: null,
            },
        };
    },

    computed: {
        backgroundImageBoxStyle() {
            return {
                top: length(this.backgroundImageBox.top),
                left: length(this.backgroundImageBox.left),
                width: length(this.backgroundImageBox.width),
                height: length(this.backgroundImageBox.height),
            };
        },

        innerFrameStyle() {
            return {
                top: length(this.innerFrame.top),
                left: length(this.innerFrame.left),
                width: length(this.innerFrame.width),
                height: length(this.innerFrame.height),
            };
        },

        frontImageBoxStyle() {
            return {
                width: length(this.backgroundImageBox.width),
                height: length(this.backgroundImageBox.height),
                marginTop: length(this.backgroundImageBox.top - this.innerFrame.top - 1),
                marginLeft:length(this.backgroundImageBox.left - this.innerFrame.left - 1),
            };
        },

        minLeftBackgroundImageBox() {
            return this.innerFrame.left + this.innerFrame.width - this.backgroundImageBox.width - 1;
        },

        minTopBackgroundImageBox() {
            return this.innerFrame.top + this.innerFrame.height - this.backgroundImageBox.height - 1;
        },

        maxLeftBackgroundImageBox() {
            return this.innerFrame.left + 1;
        },

        maxTopBackgroundImageBox() {
            return this.innerFrame.top + 1;
        },

        sliderStyle() {
            let left = 0;
            const min = this.scale.min;
            const max = this.scale.max;
            const diff = max - min;
            const value = this.scale.value - min;
            const percent = value / diff * 100;

            if (percent > 50) {
                left = Math.ceil(percent) + '%';
            }
            else {
                left = Math.floor(percent) + '%';
            }

            return {
                left,
            };
        },

        imageRatio() {
            const width = this.originalImageWidth;
            const height = this.originalImageHeight;

            return (width && height) ? width / height : 1;
        },

        ratio() {
            const width = this.innerFrame.width;
            const height = this.innerFrame.height;

            return (width && height) ? width / height : 1;
        },
    },

    mounted() {
        this.init();
        this.saveOuterFrameResolution();
        window.addEventListener('resize', this.onResize);
    },

    beforeDestroy() {
        window.addEventListener('resize', this.onResize);
    },

    methods: {
        async init() {
            this.calcInnerFrame();
            await this.calcOriginalImageResolution();
            this.calcScale();
            this.calcBackgroundImageBoxResolution();
        },

        saveOuterFrameResolution() {
            const el = this.$refs.outerFrame;

            if (!el) return;

            const { width, height } = el.getBoundingClientRect();
            this.oldOuterFrame.width = width;
            this.oldOuterFrame.height = height;
        },

        repaint() {
            this.backgroundImageBox.top = null;
            this.backgroundImageBox.left = null;
            this.init();
        },

        calcInnerFrame() {
            let top = null;
            let left = null;
            let width = null;
            let height = null;
            const outerFrame = this.$refs.outerFrame;
            const outerFrameRect = outerFrame.getBoundingClientRect();
            const outerFrameWidth = outerFrameRect.width;
            const outerFrameHeight = outerFrameRect.height;

            if (outerFrameWidth > outerFrameHeight) {
                // горизонтальный фрейм
                width = this.width >= outerFrameWidth ? outerFrameWidth : this.width;
                const scale = width / this.width;
                height = this.height * scale;
            }
            else {
                // вертикальные или квадратный фрейм
                height = this.height >= outerFrameHeight ? outerFrameHeight : this.height;
                const scale = height / this.height;
                width = this.width * scale;
            }

            top = outerFrameHeight > height ? (outerFrameHeight - height) / 2 : 0;
            left = outerFrameWidth > width ? (outerFrameWidth - width) / 2 : 0;

            this.innerFrame.top = top;
            this.innerFrame.left = left;
            this.innerFrame.width = width;
            this.innerFrame.height = height;
        },

        async calcOriginalImageResolution() {
            return new Promise(resolve => {
                if (this.originalImageWidth && this.originalImageHeight) {
                    resolve();
                    return;
                }

                const imageElement = new Image();

                imageElement.src = this.src;

                imageElement.onload = () => {
                    this.originalImageWidth = imageElement.width;
                    this.originalImageHeight = imageElement.height;

                    resolve();
                };
            });
        },

        calcScale() {
            let min = 0;
            const innerFrameWidth = this.innerFrame.width;
            const innerFrameHeight = this.innerFrame.height;
            const ratio = innerFrameWidth / innerFrameHeight;
            const originalImageWidth = this.originalImageWidth;
            const originalImageHeight = this.originalImageHeight;
            const imageRatio = originalImageWidth / originalImageHeight;

            if (imageRatio < ratio) {
                // ширина изображения равна ширине inner frame
                const width = innerFrameWidth - 2;
                min = width / originalImageWidth;
            }
            else {
                // высота изображения равна высоте inner frame
                const height = innerFrameHeight - 2;
                min = height / originalImageHeight;
            }

            const max = this.scale.max;
            const diff = max - min;
            const step = diff / 10;
            this.scale.steps = new Array(11).fill('').map((_, index) => index * step + min);
            this.scale.steps[10] = max;
            this.scale.value = min;
            this.scale.min = min;
            this.scale.has = this.innerFrame.width < this.originalImageWidth
                && this.innerFrame.height < this.originalImageHeight;
        },

        calcBackgroundImageBoxResolution() {
            let x = 0;
            let y = 0;
            const top = this.backgroundImageBox.top;
            const left = this.backgroundImageBox.left;
            const scale = this.scale.value;
            const originalImageWidth = this.originalImageWidth;
            const originalImageHeight = this.originalImageHeight;
            const newWidth = originalImageWidth * scale;
            const newHeight = originalImageHeight * scale;

            if (top === null && left === null) {
                // start position
                const innerFrameTop = this.innerFrame.top;
                const innerFrameLeft = this.innerFrame.left;
                const innerFrameWidth = this.innerFrame.width;
                const innerFrameHeight = this.innerFrame.height;
                const ratio = this.ratio;
                const imageRatio = this.imageRatio;

                if (imageRatio < ratio) {
                    // ширина изображения равна ширине inner frame
                    x = innerFrameLeft + 1;
                    y = innerFrameTop + (innerFrameHeight - newHeight) / 2;
                }
                else {
                    // высота изображения равна высоте inner frame
                    y = innerFrameTop + 1;
                    x = innerFrameLeft + (innerFrameWidth - newWidth) / 2;
                }
            }
            else {
                const oldWidth = this.backgroundImageBox.width;
                const oldHeight = this.backgroundImageBox.height;
                const widthDiff = oldWidth - newWidth;
                const heightDiff = oldHeight - newHeight;
                x = widthDiff / 2;
                y = heightDiff / 2;
            }

            this.backgroundImageBox.width = newWidth;
            this.backgroundImageBox.height = newHeight;

            this.calcBackgroundImageBoxPosition(x, y);
        },

        calcBackgroundImageBoxPosition(x, y) {
            if (x || y) {
                let left = (this.backgroundImageBox.left || 0) + x;
                let top = (this.backgroundImageBox.top || 0) + y;

                if (left < this.minLeftBackgroundImageBox) {
                    left = this.minLeftBackgroundImageBox;
                }

                if (left > this.maxLeftBackgroundImageBox) {
                    left = this.maxLeftBackgroundImageBox;
                }

                if (top < this.minTopBackgroundImageBox) {
                    top = this.minTopBackgroundImageBox;
                }

                if (top > this.maxTopBackgroundImageBox) {
                    top = this.maxTopBackgroundImageBox;
                }

                this.backgroundImageBox.left = left;
                this.backgroundImageBox.top = top;
            }
        },

        onMoveLayerMouseDown($event) {
            const primaryButton = $event.buttons === 1;

            if (!primaryButton) return;

            let prevX = $event.clientX;
            let prevY = $event.clientY;

            const onMouseMove = e => {
                const x = e.clientX - prevX;
                const y = e.clientY - prevY;

                this.calcBackgroundImageBoxPosition(x, y);

                prevX = e.clientX;
                prevY = e.clientY;
            };

            const t = e => throttle(onMouseMove(e), 10);

            document.addEventListener('mousemove', t);

            document.addEventListener('mouseup', () => {
                document.removeEventListener('mousemove', t);
            }, { once: true });
        },

        onMouseDownSlider($event) {
            const primaryButton = $event.buttons === 1;

            if (!primaryButton) return;

            const { clientX } = $event;
            const { slider, sliderContainer } = this.$refs;
            const sliderRect = slider.getBoundingClientRect();
            const sliderLeft = sliderRect.left;
            const leftOffset = clientX - sliderLeft;
            const sliderContainerRect = sliderContainer.getBoundingClientRect();
            const sliderContainerLeft = sliderContainerRect.left;
            const left = sliderContainerLeft + leftOffset;
            const width = sliderContainerRect.width;

            const onMouseMove = e => {
                const x = e.clientX;

                if (x < left) {
                    this.scale.value = this.scale.min;
                }
                else if (x > (left + width)) {
                    this.scale.value = this.scale.max;
                }
                else {
                    const scaleMin = this.scale.min;
                    const scaleMax = this.scale.max;
                    const diff = scaleMax - scaleMin;
                    const value = x - left;
                    const percent = value / width;
                    this.scale.value = diff * percent + scaleMin;
                }

                this.calcBackgroundImageBoxResolution();

                // console.group('slider');
                // console.log('left', left);
                // console.log('width', width);
                // console.log('x', x);
                // console.log('value', value);
                // console.log('percent', percent);
                // console.groupEnd();
            };

            const t = e => throttle(onMouseMove(e), 10);

            document.addEventListener('mousemove', t);

            document.addEventListener('mouseup', () => {
                document.removeEventListener('mousemove', t);
            }, { once: true });
        },

        onMinusClick() {
            const value = this.scale.value;
            const min = this.scale.min;

            if (value <= min) return;

            const steps = [...this.scale.steps].reverse();

            for (let i = 0; i < steps.length; i++) {
                const step = steps[i];

                if (value > step) {
                    this.scale.value = step;
                    break;
                }
            }

            this.calcBackgroundImageBoxResolution();
        },

        onPlusClick() {
            const value = this.scale.value;

            if (value >= 1) return;

            const steps = this.scale.steps;

            for (let i = 0; i < steps.length; i++) {
                const step = steps[i];

                if (value < step) {
                    this.scale.value = step;
                    break;
                }
            }

            this.calcBackgroundImageBoxResolution();
        },

        onResize() {
            const outerFrame = this.$refs.outerFrame;

            if (!outerFrame) return;

            const { width, height } = outerFrame.getBoundingClientRect();

            if (width !== this.oldOuterFrame.width || height !== this.oldOuterFrame) {
                this.calcInnerFrame();

                const x = (width - this.oldOuterFrame.width) / 2;
                const y = (height - this.oldOuterFrame.height) / 2;

                this.calcBackgroundImageBoxPosition(x, y);

                this.oldOuterFrame.width = width;
                this.oldOuterFrame.height = height;
            }
        },

        crop() {
            return new Promise(resolve => {
                const image = this.$refs.image;
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const x = Math.abs(this.backgroundImageBox.left - this.innerFrame.left - 1) / this.scale.value;
                const y = Math.abs(this.backgroundImageBox.top - this.innerFrame.top - 1) / this.scale.value;
                const width = this.innerFrame.width / this.scale.value - 1;
                const height = this.innerFrame.height / this.scale.value - 1;

                ctx.canvas.width = width;
                ctx.canvas.height = height;

                ctx.drawImage(image, x, y, width, height, 0, 0, width, height);

                canvas.toBlob(blob => {
                    const file = this.fileFromBlob(blob, 'image.jpg');
                    resolve(file);
                }, 'image/jpeg', .95);
            });
        },

        fileFromBlob(blob, name) {
            if (!navigator.msSaveBlob) {
                const fd = new FormData();
                fd.set('a', blob, name);
                return fd.get('a');
            }
            else {
                const file = blob;
                file.name = name;
                file.lastModified = file.lastModifiedDate = new Date();
                return file;
            }
        },

        onPinchStart() {
            startImageWidth = this.backgroundImageBox.width;
        },

        onPinchMove($event) {
            let { scale } = $event;

            const width = startImageWidth * scale;
            let value = width / this.originalImageWidth;

            if (value > this.scale.max) {
                value = this.scale.max;
            }
            else if (value < this.scale.min) {
                value = this.scale.min;
            }

            this.scale.value = value;

            this.calcBackgroundImageBoxResolution();
        },

        onPanEnd() {
            prevPanX = 0;
            prevPanY = 0;
        },

        onPanMove($event) {
            const x = $event.deltaX - prevPanX;
            const y = $event.deltaY - prevPanY;

            this.calcBackgroundImageBoxPosition(x, y);

            prevPanX = $event.deltaX;
            prevPanY = $event.deltaY;
        },

        onTouchSlider($event) {
            const { touches } = $event;

            if (touches.length !== 1) return;

            const touch = touches[0];
            const { clientX } = touch;
            const { slider, sliderContainer } = this.$refs;
            const sliderRect = slider.getBoundingClientRect();
            const sliderLeft = sliderRect.left;
            const leftOffset = clientX - sliderLeft;
            const sliderContainerRect = sliderContainer.getBoundingClientRect();
            const sliderContainerLeft = sliderContainerRect.left;
            const left = sliderContainerLeft + leftOffset;
            const width = sliderContainerRect.width;

            const move = e => {
                const { changedTouches } = e;
                const touch = changedTouches[0];
                const x = touch.clientX;

                if (x < left) {
                    this.scale.value = this.scale.min;
                }
                else if (x > (left + width)) {
                    this.scale.value = this.scale.max;
                }
                else {
                    const scaleMin = this.scale.min;
                    const scaleMax = this.scale.max;
                    const diff = scaleMax - scaleMin;
                    const value = x - left;
                    const percent = value / width;
                    this.scale.value = diff * percent + scaleMin;

                    // console.group('slider');
                    // console.log('left', left);
                    // console.log('width', width);
                    // console.log('x', x);
                    // console.log('value', value);
                    // console.log('percent', percent);
                    // console.groupEnd();
                }

                this.calcBackgroundImageBoxResolution();
            };

            const t = throttle(move, 10);

            document.addEventListener('touchmove', t);
            document.addEventListener('touchend', () => {
                document.removeEventListener('touchmove', t);
            }, { once: true });
        },
    },
};
</script>

<style>
.image-cropper__outer-frame {
    position: relative;
    border: 1px solid var(--border-light-c);
    border-radius: var(--border-radius);
    box-sizing: border-box;
}

.image-cropper__outer-frame::before {
    content: "";
    display: block;
    padding-top: 100%;
}

.image-cropper__background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.image-cropper__background-image-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
}

.image-cropper__background-image-box {
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
}

.image-cropper__image {
    user-select: none;
    width: 100%;
    height: 100%;
}

.image-cropper__haze {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    opacity: .4;
}

.image-cropper__inner-frame {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid var(--border-dark-c);
    box-sizing: border-box;
}

.image-cropper__inner-frame.image-cropper__inner-frame_round {
    border-radius: 50%;
}

.image-cropper__front-image-box {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.image-cropper__touch-layer {
    width: 100%;
    height: 100%;
}

.image-cropper__move-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: move;
    pointer-events: all;
}

.image-cropper__zoom-wrap {
    margin-top: 24px;
    display: flex;
}

.image-cropper__zoom-container {
    margin-left: auto;
    margin-right: auto;
}

.image-cropper__zoom-panel {
    position: relative;
    width: 198px;
    height: 28px;
    padding: 2px;
    display: flex;
    overflow: hidden;
    border-radius: 14px;
}

.image-cropper__zoom-panel::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: var(--dark-c);
    opacity: .4;
    border-radius: 14px;
    border: 1px solid var(--fields-border);
    /*z-index: -1;*/
}

.image-cropper__zoom-button {
    position: relative;
    width: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity var(--transition);
}

.image-cropper__zoom-button:disabled {
    opacity: .4;
}

.image-cropper__zoom-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    background: var(--dark-c);
    transition: opacity var(--transition);
}

.image-cropper__zoom-button:hover::before {
    opacity: .1;
}

.image-cropper__zoom-button:active::before {
    opacity: .2;
}

.image-cropper__zoom-button .image-cropper__zoom-button-icon {
    fill: var(--light-c);
}

.image-cropper__zoom-minus {
    margin-right: 4px;
}

.image-cropper__zoom-rails {
    position: relative;
    width: 102px;
    cursor: pointer;
    padding-right: 24px;
}

.image-cropper__zoom-rails::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--dark-c);
    opacity: .4;
    border: 1px solid var(--fields-border);
    border-radius: 12px;
}

.image-cropper__zoom-slider-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.image-cropper__zoom-slider {
    position: absolute;
    width: 24px;
    height: 24px;
    margin-left: -1px;
    margin-right: -1px;
    background: var(--light-c);
    border-radius: 50%;
}

.image-cropper__zoom-plus {
    margin-left: 4px;
}
</style>