
<div
    class="file-loader"
    :class="{
        'file-loader_drag': drag,
        'file-loader_empty': isEmpty,
        'file-loader_invalid': invalid,
    }"
    @dragenter.prevent.stop="handleDrag"
    @dragover.prevent.stop="handleDrag"
    @dragleave.prevent.stop="handleOutDrag"
    @drop.prevent="handleDrop"
>
    <span
        v-if="label"
        class="file-loader__field-label"
        :class="{
            'file-loader__field-label_offset': innerItems.length,
            'file-loader__field-label_invalid': invalid,
        }"
    >{{ label }}</span>

    <div
        v-if="innerItems.length"
        class="file-loader__loaded-items-list"
    >
        <div
            v-for="(obj, index) in innerItems"
            :key="obj.__id || index"
            class="file-loader__loaded-item"
        >
            <div class="file-loader__loaded-item-preview-wrap">
                <img
                    v-if="isImage"
                    :src="obj.src || ($links.uploads + getItemSrc(obj.item))"
                    :alt="obj.item.name"
                    class="file-loader__loaded-item-preview-img"
                    :class="{
                        'file-loader__loaded-item-preview-img_loading': obj.loading,
                    }"
                >
                <div
                    v-else
                    class="file-loader__loaded-item-preview-icon-wrap"
                >
                    <UIcon
                        name="file-loader"
                        class="file-loader__loaded-item-preview-icon"
                    ></UIcon>
                    <span class="file-loader__loaded-item-preview-icon-extension">{{ obj.item.extension }}</span>
                </div>

                <div class="file-loader__progress-bar-wrap">
                    <LoaderProgressBar
                        v-if="obj.loading"
                        :progress="obj.loadingProgress"
                        class="file-loader__progress-bar"
                    ></LoaderProgressBar>
                </div>
            </div>

            <div class="file-loader__loaded-item-info">
                <p class="file-loader__text-block file-loader__file-description">
                    <ButtonText
                        :pseudo="!obj.item[urlKey]"
                        :href="$links.uploads + obj.item[urlKey]"
                        :underline="!!obj.item[urlKey]"
                        target="_blank"
                        download
                        class="file-loader__file-description-button"
                    >
                        <span
                            :title="obj.item.name"
                            class="file-loader__file-name"
                        >{{ clearName(obj.item.name, obj.item.extension) }}</span>
                        <span
                            v-if="obj.item.extension"
                            class="file-loader__file-extension"
                        >.{{ obj.item.extension }}</span>
                    </ButtonText>

                    <span class="file-loader__file-size">({{ getSizeString(obj.item[sizeKey]) }})</span>
                </p>
                <p
                    v-if="obj.errors.size"
                    class="file-loader__text-block file-loader__file-error"
                >
                    Максимальный размер файла {{ maxSize }} МБ
                </p>
                <p
                    v-else-if="obj.errors.extension"
                    class="file-loader__text-block file-loader__file-error"
                >
                    Можно загружать только {{ normalizedAccept.join(', ') }}
                </p>
                <p
                    v-else-if="obj.errors.resolution"
                    class="file-loader__text-block file-loader__file-error"
                >
                    Минимальное разрешение изображения {{ width }}x{{ height }} рх
                </p>
            </div>

            <ButtonIcon
                :icon="{
                    name: 'cross',
                    small: true,
                    secondary: true,
                    hovered: true
                }"
                :secondary="false"
                class="file-loader__delete-btn"
                @click="deleteItem(index)"
            ></ButtonIcon>
        </div>
    </div>

    <label
        v-if="innerItems.length < maxItems"
        :for="'file-loader-input_' + id"
        class="file-loader__action-label"
        :class="{
            'file-loader_empty__action-label': innerItems.length === 0
        }"
    >
        <span
            class="file-loader__text-block file-loader__placeholder"
            :class="{
                'file-loader__placeholder_invalid': isEmpty && invalid,
            }"
        >
            <ButtonText
                underline
                primary
                pseudo
                class="d-inline-block"
            >Выберите</ButtonText> {{ chooseString }}
        </span>
    </label>

    <span
        class="file-loader__limitations-text"
        :class="{
            'file-loader__limitations-text_invalid': isEmpty && invalid,
        }"
    >
        {{ info }}
    </span>

    <ButtonText
        v-if="hasInvalidFiles"
        dashed
        dark
        secondary
        class="mt-16"
        @click="removeInvalidFiles"
    >
        Удалить все {{ isImage ? 'изображения' : 'файлы' }} с ошибками
    </ButtonText>

    <input
        :id="'file-loader-input_' + id"
        ref="input"
        type="file"
        name="file-loader"
        class="visually-hidden file-loader__input"
        :accept="acceptComputed"
        :multiple="isMulti ? 'multiple' : null"
        @change="addFiles($event.target.files)"
    >

    <UPopup ref="cropper">
        <FileLoaderCropperPopup
            :src="cropper.src"
            :width="parseInt(width)"
            :height="parseInt(height)"
            @close="onCloseCropper"
            @cropped="onCropped"
        ></FileLoaderCropperPopup>
    </UPopup>
</div>
