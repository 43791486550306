import useBaseApi from '@/api/useBaseApi.js';
import { HTTP } from '@/services/http.js';


const { get, add, change, remove, aggregations } = useBaseApi('generations');

export default {
    get,
    add,
    change,
    remove,
    aggregations,

    async addPartNameRelation(id) {
        const url = `/generations/${ id }/add_part_name_relation/`;
        const response = await HTTP.post(url);
        return response.data;
    },

    async setClassificationComplete(id) {
        const url = `/generations/${ id }/set_classification_complete/`;
        const response = await HTTP.post(url);
        return response.data;
    },

    async setClassificationUnComplete(id) {
        const url = `/generations/${ id }/set_classification_uncomplete/`;
        const response = await HTTP.post(url);
        return response.data;
    },

};