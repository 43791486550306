import bus from '@/services/bus.js';


export default {
    success(message = 'Операция успешно выполнена') {
        bus.$emit('notify', { message, type: 'success' });
    },

    error(message = 'Не удалось совершить операцию') {
        bus.$emit('notify', { message, type: 'error' });
    },
};