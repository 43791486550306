
<TransitionGroup
    tag="div"
    name="the-notification"
    class="the-notification"
>
    <div
        v-for="item in queue"
        :key="item.id"
        class="the-notification__message"
        :class="['the-notification__message_' + item.type]"
        @mouseover="onMouseover"
        @mouseleave="onMouseleave"
    >
        <div class="the-notification__message-text-wrap">
            <p
                v-for="(message, messageIndex) in item.texts"
                :key="messageIndex"
                class="the-notification__message-text"
            >
                {{ message }}
            </p>
        </div>
    </div>
</TransitionGroup>
