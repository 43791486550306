import api from '@/api';


export default {
    namespaced: true,

    state: {
        isAuthorized: false,
        info: {},
        fetching: false,
    },

    mutations: {
        setInfo(state, value) {
            state.info = value;
        },

        setFetching(state, value) {
            state.fetching = value;
        },

        setIsAuthorized(state, value) {
            state.isAuthorized = value;
        },
    },

    actions: {
        async fetch({ commit }) {
            commit('setFetching', true);

            try {
                const response = await api.profile.info();
                commit('setInfo', response);
                commit('setIsAuthorized', true);
            }
            catch (error) {
                const code = (error.response || {}).status;

                if (code === 401) {
                    commit('setIsAuthorized', false);
                }

                throw error;
            }
            finally {
                commit('setFetching', false);
            }
        },
    },
};