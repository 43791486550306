import { HTTP } from '@/services/http.js';


export default str => {
    return {
        async get(...args) {
            let config;
            let id;

            if (args && args.length) {
                if (typeof args[0] === 'string' || typeof args[0] === 'number') {
                    id = args[0];
                    config = args[1];
                }
                else {
                    config = args[0];
                }
            }

            if (!config) config = {};

            if (id) {
                const url = `/${ str }/${ id }/`;
                const response = await HTTP.get(url, config);
                return response.data;
            }
            else {
                const url = `/${ str }/`;
                const response = await HTTP.get(url, config);
                return response.data;
            }
        },

        async add(data) {
            const url = `/${ str }/`;
            const response = await HTTP.post(url, data);
            return response.data;
        },

        async change(id, data, config) {
            const url = `/${ str }/${ id }/`;
            const response = await HTTP.put(url, data, config);
            return response.data;
        },

        async update(id, data, config) {
            const url = `/${ str }/${ id }/`;
            const response = await HTTP.patch(url, data, config);
            return response.data;
        },

        async aggregations(config) {
            const url = `/${ str }/aggregations/`;
            const response = await HTTP.get(url, config);
            return response.data;
        },

        async remove(id) {
            const url = `/${ str }/${ id }/`;
            const response = await HTTP.delete(url);
            return response.data;
        },
    }
};