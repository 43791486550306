export default [
    {
        path: '/part-names',
        name: 'part-names-list',
        component: () => import('./index.vue'),
    },
    {
        path: '/part-names/:id',
        name: 'part-names-form',
        component: () => import('./_id.vue'),
    }
]