
<component
    :is="tag"
    class="u-icon-button"
    :class="{
        'u-icon-button_active': active,
        'u-icon-button_disabled': disabled,
        'u-icon-button_border': (round || square) && !shadow,
        'u-icon-button_round': round,
        'u-icon-button_square': square,
        'u-icon-button_shadow': shadow,
        'u-icon-button_primary': primary,
        'u-icon-button_secondary': secondary,
        'u-icon-button_red': red,
        'u-icon-button_yellow': yellow,
        'u-icon-button_used': used,
        'u-icon-button_tiny': tiny,
    }"
    v-bind="{
        ...$attrs,
        ...attrs,
    }"
    v-on="$listeners"
>
    <USpinner
        v-if="loading"
        v-bind="{
            ...spinner,
        }"
    ></USpinner>
    <UIcon
        v-else
        :name="name"
        :tiny="tiny"
        :small="small"
        :big="big"
        class="u-icon-button__icon"
    ></UIcon>
</component>
