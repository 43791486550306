
<component
    :is="tag"
    type="button"
    tabindex="-1"
    class="u-button-round"
    :class="{
        'u-button-round_primary': primary,
        'u-button-round_shadow': shadow,
        'u-button-round_small': small,
        'u-button-round_mini': mini,
    }"
    :disabled="disabled"
    :aria-label="label"
    v-on="$listeners"
>
    <UIcon
        :name="name"
        :small="small"
        :tiny="mini"
        class="u-button-round__icon"
    ></UIcon>
</component>
