
<div
    class="u-textarea-field"
    :class="{
        'u-textarea-field_disabled': disabled,
        'u-textarea-field_focused': isFocused,
        'u-textarea-field_invalid': invalid,
        'u-textarea-field_filled': !!value,
    }"
>
    <div
        ref="border"
        class="u-textarea-field__border-box"
        @click="onClick"
        @mousedown="onMouseDown"
    >
        <UTextarea
            ref="textarea"
            class="u-textarea-field__input"
            v-bind="attrs"
            v-on="handlers"
        ></UTextarea>

        <ULabel
            v-if="label || $scopedSlots.label"
            :id="id"
            :active="isFocused || !!value"
            :invalid="invalid"
            :disabled="disabled"
        >
            <slot name="label">
                {{ label }}
            </slot>
        </ULabel>

        <div class="u-textarea-field__icon-box u-textarea-field__append-inner">
            <UTooltip
                v-if="hint || $scopedSlots.hint || $scopedSlots['hint-content']"
                key="hint"
            >
                <template #trigger="{ attrs, handlers, value }">
                    <UTooltipIcon
                        :active="value"
                        v-bind="attrs"
                        class="u-input-simple__icon"
                        v-on="handlers"
                    ></UTooltipIcon>
                </template>

                <template #content>
                    <slot name="hint-content">
                        <UTooltipSimpleTemplate>
                            <slot name="hint">
                                {{ hint }}
                            </slot>
                        </UTooltipSimpleTemplate>
                    </slot>
                </template>
            </UTooltip>

            <UIconButton
                v-if="value"
                key="close"
                name="close-16"
                small
                secondary
                tabindex="-1"
                class="u-textarea-field__clear"
                @click="clear"
                @mousedown.native.stop=""
            ></UIconButton>
        </div>
    </div>
</div>
