import arrayify from './arrayify.js';


export default (obj, keys) => {
    let arr = arrayify(keys);
    let temp = obj;
    let key = arr.pop();

    for (let k of arr) {
        if (temp[k] !== undefined) {
            temp = temp[k];
        }
        else {
            return false;
        }
    }

    return Object.prototype.hasOwnProperty.call(obj, key);
};