
<TransitionExpand>
    <div
        v-if="show"
        key="u-form-field-error"
        class="u-form-field-error"
    >
        <slot></slot>
    </div>
</TransitionExpand>
