
<div class="u-field-text">
    <UTextareaField
        :invalid="invalid"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template
            v-for="slotName in Object.keys($scopedSlots)"
            #[slotName]="payload"
        >
            <slot :name="slotName" v-bind="payload"></slot>
        </template>
    </UTextareaField>

    <UFormFieldError
        :show="invalid"
        class="u-field-text__error"
    >
        {{ error }}
    </UFormFieldError>
</div>
