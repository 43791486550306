<template>
    <TransitionGroup
        tag="div"
        name="the-notification"
        class="the-notification"
    >
        <div
            v-for="item in queue"
            :key="item.id"
            class="the-notification__message"
            :class="['the-notification__message_' + item.type]"
            @mouseover="onMouseover"
            @mouseleave="onMouseleave"
        >
            <div class="the-notification__message-text-wrap">
                <p
                    v-for="(message, messageIndex) in item.texts"
                    :key="messageIndex"
                    class="the-notification__message-text"
                >
                    {{ message }}
                </p>
            </div>
        </div>
    </TransitionGroup>
</template>

<script>
import bus from '@/services/bus.js';
import genId from '@ui/utils/genId.js';


export default {
    name: 'TheNotification',

    data() {
        return {
            queue: [
                // {
                //     type: 'error',
                //     text: '',
                //     show: true,
                //     timeout: null,
                // },
            ],
        };
    },

    mounted() {
        bus.$on('notify', this.add);
    },

    beforeDestroy() {
        bus.$off('notify', this.add);
    },

    methods: {
        add({ type, message }) {
            const texts = this.normalizeTexts(message);
            const id = genId();
            const item = { type, texts, id };

            this.queue.splice(0, 0, item);

            setTimeout(() => {
                this.removeItem(item);
            }, 3000);
        },

        normalizeTexts(text) {
            if (Array.isArray(text)) return text;
            return [text];
        },

        removeItem(item) {
            const index = this.queue.findIndex(i => i.id === item.id);
            if (index === -1) return;
            this.queue.splice(index, 1);
        },

        onMouseover() {

        },

        onMouseleave() {

        },
    },
};
</script>

<style scoped>
.the-notification {
    position: fixed;
    top: 24px;
    right: 24px;
    z-index: 100000;
}

.the-notification-enter,
.the-notification-leave-to {
    right: -380px;
    opacity: 0;
}

.the-notification-enter-to,
.the-notification-leave {
    right: 0;
    opacity: 1;
}

.the-notification-active {
    position: absolute;
}

.the-notification__close {
    position: absolute;
    top: 12px;
    right: 12px;
}

.the-notification__message {
    position: relative;
    width: 300px;
    padding: 20px 24px;
    border-radius: var(--border-radius);
    background-color: var(--light-c);
    box-shadow: var(--base-shadow);
    transition: all var(--transition);
}
.the-notification__message:not(:last-child) {
    margin-bottom: 20px;
}
.the-notification__message.the-notification__message_error {
    border-left: 4px solid var(--primary-color);
}
.the-notification__message.the-notification__message_success {
    border-left: 4px solid var(--accent-green);
}

.the-notification__message-text {
    line-height: var(--base-lh);
}

.the-notification__message-text:not(:last-child) {
    margin-bottom: 4px;
}

/*@keyframes showNotification {*/
/*    0% {*/
/*        right: -380px;*/
/*        opacity: 0;*/
/*    }*/

/*    100% {*/
/*        right: 24px;*/
/*        opacity: 1;*/
/*    }*/
/*}*/

/*@keyframes hideNotification {*/
/*    0% {*/
/*        right: 24px;*/
/*        opacity: 1;*/
/*    }*/

/*    100% {*/
/*        right: -380px;*/
/*        opacity: 0;*/
/*    }*/
/*}*/
</style>
