
<li class="u-menu-item">
    <slot name="content">
        <ButtonText
            class="u-menu-item__button"
            :aria-selected="selected"
            :disabled="disabled"
            :class="{
                'u-menu-item__button_selected': selected
            }"
            v-bind="$attrs"
            v-on="$listeners"
        >
            <slot></slot>
        </ButtonText>
    </slot>
</li>
