<template>
    <div class="file-loader-field">
        <FileLoader
            :invalid="invalid"
            :maxItems="maxItems"
            v-bind="$attrs"
            v-on="$listeners"
        ></FileLoader>
    </div>
</template>

<script>
import fieldMixin from '@ui/components/UForm/fields/fieldMixin.js';
import FileLoader from '@/components/FileLoader.vue';


export default {
    name: 'FileLoaderField',

    components: { FileLoader },

    mixins: [fieldMixin],

    props: {
        maxItems: Number,
    },

    data() {
        const maxItems = ((this.$options || {}).propsData || {}).maxItems || 1;
        const initialValue = maxItems > 1 ? [] : null;

        return {
            initialValue,
        };
    },
};
</script>