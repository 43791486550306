import store from '@/store';
import { authRoutesNames } from '@/services/router.js';
import signinRoutes from './signin/__routes__.js';
import passwordRecoveryRoutes from './passwordRecovery/__routes__.js';
import passwordChangeRoutes from './passwordChange/__routes__.js';
import partNamesCars from './partLinks/__routes__.js';
import partNames from './partNames/__routes__.js';
import partCategoriesRoutes from './partCategories/__routes__.js';
import partAttributesRoutes from './partAttributes/__routes__.js';
import mediafilesRoutes from './mediafiles/__routes__.js';


export default [
    {
        path: '/',
        name: 'index',
        beforeEnter(to, from, next) {
            if (store.state.app.initialized) {
                if (store.state.profile.isAuthorized) {
                    next({
                        name: 'part-names-list',
                    });
                }
                else {
                    if (authRoutesNames.includes(to.name)) {
                        next();
                    }
                    else {
                        next({
                            name: 'signin',
                        });
                    }
                }
            }
            else {
                next();
            }
        },
    },
    ...signinRoutes,
    ...passwordRecoveryRoutes,
    ...passwordChangeRoutes,
    ...partNamesCars,
    ...partNames,
    ...partCategoriesRoutes,
    ...partAttributesRoutes,
    ...mediafilesRoutes,
    // todo: * -> 404
];