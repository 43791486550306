
<label
    class="u-checkbox__wrap"
    :class="{
        'u-checkbox__wrap_disabled': disabled,
        'u-checkbox__wrap_invalid': invalid,
        'u-checkbox__wrap_border': border,
        'u-checkbox__wrap_checked': value,
    }"
>
    <input
        :id="id"
        :checked="value"
        type="checkbox"
        class="visually-hidden u-checkbox__input"
        :class="{
            'u-checkbox__input_indeterminate': indeterminate
        }"
        :disabled="disabled"
        @click="toggle"
    >

    <span class="u-checkbox__icon-wrap">
        <UIcon
            v-if="indeterminate"
            name="minus"
            light
            small
            class="u-checkbox__icon u-checkbox__icon_minus"
        ></UIcon>

        <UIcon
            v-else
            name="done"
            light
            small
            class="u-checkbox__icon u-checkbox__icon_check"
        ></UIcon>
    </span>

    <span class="u-checkbox__label">
        <slot name="label">
            {{ label }}
        </slot>
    </span>
</label>
