import Vue from 'vue';
import Vuex from 'vuex';
import app from './app.js';
import loader from './loader.js';
import profile from './profile.js';
import error from './error.js';


Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        app,
        loader,
        profile,
        error,
    },
});

export default store;