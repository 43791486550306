
<component
    :is="tag"
    v-bind="attrs"
    class="u-button-block"
    :class="{
        'u-button-block_disabled': disabled,
        'u-button-block_primary': primary,
        'u-button-block_secondary': secondary,
        'u-button-block_blue': blue,
        'u-button-block_loading': loading,
        'u-button-block_active': active,
        'u-button-block_low': low,
        'u-button-block_narrow': narrow || narrowComputed,
        'u-button-block_fixed': fixed,
    }"
    @click="$emit('click', $event)"
>
    <USpinner
        :light="primary"
        :dark="secondary"
        :small="low"
        class="u-button-block__spinner"
        :class="{
            'u-button-block__spinner_loading': loading,
        }"
    ></USpinner>

    <span
        class="u-button-block__label"
        :class="{
            'u-button-block__label_loading': loading
        }"
    >
        <slot name="prepend-icon">
            <UIcon
                v-if="prependIcon"
                v-bind="prependIcon"
                class="mr-8 u-button-block__icon u-button-block__prepend-icon"
            ></UIcon>
        </slot>

        <slot>
            {{ label }}
        </slot>

        <slot name="append-icon">
            <UIcon
                v-if="appendIcon"
                v-bind="appendIcon"
                class="ml-8 u-button-block__icon u-button-block__append-icon"
            ></UIcon>
        </slot>
    </span>
</component>
