import auth from '@/api/auth.js';
import profile from '@/api/profile.js';
import generations from '@/api/generations.js';
import partNames from '@/api/partNames.js';
import partCategories from '@/api/partCategories.js';
import partAttributes from '@/api/partAttributes.js';
import manufacturers from '@/api/manufacturers.js';
import families from '@/api/families.js';
import markets from '@/api/markets.js';
import cars from '@/api/cars.js';
import carsDoc from '@/api/carsDoc.js';
import partNamesCars from '@/api/partNamesCars.js';
import carPartCategories from '@/api/carPartCategories.js';
import partGroups from '@/api/partGroups.js';
import partSchemes from '@/api/partSchemes.js';
import schemeCoords from '@/api/schemeCoords.js';
import partCodes from '@/api/partCodes.js';
import pnc from '@/api/pnc.js';
import tags from '@/api/tags.js';
import mediafiles from '@/api/mediafiles.js';
import mediafilesExtensions from '@/api/mediafilesExtensions.js';


export default {
    auth,
    profile,
    generations,
    partNames,
    partCategories,
    partAttributes,
    manufacturers,
    families,
    markets,
    cars,
    carsDoc,
    partNamesCars,
    carPartCategories,
    partGroups,
    partSchemes,
    schemeCoords,
    partCodes,
    pnc,
    tags,
    mediafiles,
    mediafilesExtensions,
};