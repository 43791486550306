
<div
    ref="cloud"
    class="u-tooltip-cloud"
    :class="{
        'u-tooltip-cloud_black': color === 'black',
        'u-tooltip-cloud_white': color === 'white',
        'u-tooltip-cloud_top': isTop,
        'u-tooltip-cloud_right': isRight,
        'u-tooltip-cloud_bottom': isBottom,
        'u-tooltip-cloud_left': isLeft,
        'u-tooltip-cloud_start': isStart,
        'u-tooltip-cloud_end': isEnd,
    }"
>
    <div
        class="u-tooltip-cloud__arrow-wrap"
        :class="{
            'u-tooltip-cloud__arrow-wrap_top': isTop,
            'u-tooltip-cloud__arrow-wrap_right': isRight,
            'u-tooltip-cloud__arrow-wrap_bottom': isBottom,
            'u-tooltip-cloud__arrow-wrap_left': isLeft,
            'u-tooltip-cloud__arrow-wrap_start': isStart,
            'u-tooltip-cloud__arrow-wrap_end': isEnd,
        }"
        :style="arrowWrapStyle"
    >
        <div
            class="u-tooltip-cloud__arrow"
            :class="{
                'u-tooltip-cloud__arrow_top': isBottom,
                'u-tooltip-cloud__arrow_right': isLeft,
                'u-tooltip-cloud__arrow_bottom': isTop,
                'u-tooltip-cloud__arrow_left': isRight,
            }"
        ></div>
    </div>

    <div class="u-tooltip-cloud__content">
        <slot></slot>
    </div>
</div>
