import axios from 'axios';


export let HTTP = {};

export const initHttpService = () => {
    HTTP = axios.create({
        baseURL: import.meta.env.VITE_API_URL,
        timeout: 120000,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFTOKEN',
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });

    HTTP.CancelToken = axios.CancelToken;
    HTTP.isCancel = axios.isCancel;
};