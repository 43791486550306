import useBaseApi from '@/api/useBaseApi.js';


const { get, add, change, update, remove, aggregations } = useBaseApi('part_attributes');

export default {
    get,
    add,
    change,
    update,
    remove,
    aggregations,
};