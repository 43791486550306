export default [
    {
        path: '/part-links/:generation/:modification',
        name: 'part-links-modification',
        component: () => import('./_modification.vue'),
    },
    {
        path: '/part-links/:generation',
        name: 'part-links-generation',
        component: () => import('./index.vue'),
    },
];