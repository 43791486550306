
<label
    :for="id"
    class="u-label"
    :class="{
        'u-label_active': active,
        'u-label_invalid': invalid,
        'u-label_disabled': disabled,
    }"
>
    <slot></slot>
</label>
