
<UMenu
    ref="menu"
    showOnFocus
    :shift="false"
    placement="bottom"
    :hideOnBlur="false"
    contentWidthByTrigger
    :hideOnClickContent="false"
    class="u-input-date"
>
    <template #trigger="{ value, attrs, handlers }">
        <UInputField
            ref="input"
            :value="inputValue"
            mask="00.00.0000"
            v-bind="{
                ...attrs,
                ...$attrs
            }"
            v-on="{
                ...handlers,
                ...listeners
            }"
        >
            <template #append-inner>
                <UIconButton
                    name="calendar"
                    small
                    secondary
                    class="u-input-date__icon"
                    :class="{
                        'u-input-date__icon_active': value
                    }"
                    @click="onClickIcon"
                ></UIconButton>
            </template>
        </UInputField>
    </template>

    <template #content>
        <UDatePicker
            :min="min"
            :max="max"
            :value="pickerValue"
            :stepByStep="stepByStep"
            :initialDate="initialDate"
            @click:day="onClickDay"
            @change="onChangePicker"
        ></UDatePicker>
    </template>
</UMenu>
