
<component
    :is="tag"
    v-bind="attrs"
    class="u-button-icon"
    :class="{
        'u-button-icon_secondary': secondary,
    }"
    @click="$emit('click', $event)"
>
    <slot>
        <UIcon
            v-bind="icon"
            class="u-button-icon__icon"
        ></UIcon>
    </slot>
</component>
