
<div
    class="u-input-field"
    :class="{
        'u-input-field_disabled': disabled,
        'u-input-field_focused': isFocused,
        'u-input-field_invalid': invalid,
        'u-input-field_micro': micro,
        'u-input-field_small': small,
        'u-input-field_mini': mini,
        'u-input-field_big': big,
    }"
>
    <div
        v-if="$scopedSlots['prepend-outer']"
        class="u-input-field__icon-box u-input-field__prepend-outer"
    >
        <slot name="prepend-outer"></slot>
    </div>

    <div
        ref="border"
        class="u-input-field__border-box"
        @click="onClick"
        @mousedown="onMouseDown"
    >
        <div
            v-if="$scopedSlots['prepend-inner']"
            class="u-input-field__icon-box u-input-field__prepend-inner"
        >
            <slot
                name="prepend-inner"
                v-bind="{ ...scope.attrs, ...scope.methods }"
            ></slot>
        </div>

        <UInput
            class="u-input-field__input"
            v-bind="scope.attrs"
            v-on="scope.handlers"
        ></UInput>

        <slot
            v-bind="labelScope"
            name="label-element"
        >
            <ULabel
                v-if="label || $scopedSlots.label"
                :active="!!value || isFocused"
                v-bind="labelScope.attrs"
            >
                <slot name="label">
                    {{ label }}
                </slot>
            </ULabel>
        </slot>

        <div
            v-if="$scopedSlots['append-inner']"
            class="u-input-field__icon-box u-input-field__append-inner"
        >
            <slot
                name="append-inner"
                v-bind="{ ...scope.attrs, ...scope.methods }"
            ></slot>
        </div>
    </div>

    <div
        v-if="$scopedSlots['append-outer']"
        class="u-input-field__icon-box u-input-field__append-outer"
    >
        <slot name="append-outer"></slot>
    </div>
</div>
