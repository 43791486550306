
<div class="u-field-select">
    <USelect
        :invalid="invalid"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #label="payload">
            <slot name="label" v-bind="payload"></slot>
        </template>
    </USelect>

    <UFormFieldError
        :show="invalid"
        class="u-field-select__error"
    >
        {{ error }}
    </UFormFieldError>
</div>
