import useBaseApi from '@/api/useBaseApi.js';
import { HTTP } from '@/services/http.js';


const { get, change, remove, aggregations } = useBaseApi('mediafiles');

export default {
    get,
    change,
    remove,
    aggregations,

    async removeThumbnails(id) {
        const url = `/mediafiles/${ id }/remove_thumbnails/`;
        const response = await HTTP.delete(url);
        return response.data;
    },
};