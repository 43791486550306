
<div class="u-field-string">
    <UInputSimple
        :invalid="invalid"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template
            v-for="slotName in Object.keys($scopedSlots)"
            v-slot:[slotName]="payload"
        >
            <slot :name="slotName" v-bind="payload"></slot>
        </template>
    </UInputSimple>

    <UFormFieldError
        :show="invalid"
        class="u-field-string__error"
    >
        {{ error }}
    </UFormFieldError>
</div>
