export default {
    namespaced: true,

    state: {
        initialized: false,
    },

    mutations: {
        setInitialized(state, value) {
            state.initialized = value;
        },
    },
};