
<div class="u-popup-simple-template">
    <div
        v-if="$scopedSlots.header"
        class="u-popup-simple-template__header"
    >
        <slot name="header"></slot>

        <div v-if="$scopedSlots.description" class="u-popup-simple-template__description">
            <slot name="description"></slot>
        </div>
    </div>

    <div ref="body" class="u-popup-simple-template__body">
        <slot name="body"></slot>
    </div>

    <div
        v-if="$scopedSlots.footer"
        class="u-popup-simple-template__footer"
    >
        <slot name="footer"></slot>
    </div>
</div>
