<template>
    <UPopupBaseTemplate class="u-popup-big-top-position" @close="$emit('close')">
        <slot></slot>
    </UPopupBaseTemplate>
</template>

<script>
import UPopupBaseTemplate from '@ui/components/UPopup/templates/UPopupBaseTemplate.vue';


export default {
    name: 'UPopupBigTopPosition',

    components: { UPopupBaseTemplate },
};
</script>

<style>
.u-popup-big-top-position {
    margin-top: 120px;
    margin-bottom: 48px;
}
</style>